<template>
    <div>
        <div class='input-form'>

            <div class='input-form__row'>
                <span>振込先金融機関</span>
                <div class='w-60'>
                    <el-select ref='bank' 
                        v-model='formValues.bankCode' 
                        filterable
                        remote
                        reserve-keyword
                        clearable
                        placeholder='金融機関名を入力してください' 
                        :remote-method='getFilterBankList'
                        :loading='loading'
                        size='small'>
                        <el-option v-for='item in filterBankList' :key='item.value' :label='item.value + "：" + item.label' :value='item.value'/>
                    </el-select>
                </div>
            </div>
            <div class='input-form__row'>
                <span>支店名</span>
                <div class='w-60'>
                    <el-select ref='branch' 
                        v-model='formValues.branchCode' 
                        filterable
                        remote
                        reserve-keyword
                        clearable
                        placeholder='支店名を入力してください' 
                        :remote-method='getFilterBranchList'
                        :loading='loading'
                        @focus='getBranchList'
                        size='small'>
                        <el-option v-for='item in filterBranchList' :key='item.value' :label='item.value + "：" + item.label' :value='item.value'/>
                    </el-select>
                </div>
            </div>
        </div>
        <span slot='footer'>
            <div>
                <el-button class='block w-full' type='primary' @click='selectBank'>選択</el-button>
            </div>
        </span>

		<el-dialog title='入力エラー' :visible.sync='dialogVisible.showError' append-to-body>
			<div class='p-2' v-for='(item, index) in errorList' :key='index'>
				{{ item }}
			</div>
		</el-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT = '/bank';

export default {
	name: 'SelectBank',
	props: [
        'value'
    ],
	data () {
		return {
            shokokaiList: [],
			formValues: {
                bankCode: '',
				branchCode: '',
            },
            dialogVisible: {
                showError: false
            },
            errorList: [],
            loading: false,
            bankList: [],
			filterBankList: [],
			filterBranchList: [],

		};
	},
	mounted () {
        this.getBankList(this.value);
	},
	created () {
	},
	methods: {
        async getBankList (bankType) {
            // 銀行コード一覧の取得
            let banks = await this.$ajaxPlugin.post(API_ENDPOINT, {
                action: 'get_bank_list',
                params: JSON.stringify({
                    'bankType': bankType
                })
            });
            if (banks.data.result == 'OK') {
                this.bankList = banks.data.data;
            }
        },
		async getBranchList () {
			// 支店コード一覧の取得
			let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
				action: 'get_branch_list',
				params: JSON.stringify({
					bankCode: this.formValues.bankCode
				})
			});
			if (result.data.result == 'OK') {
				this.branchList = result.data.data;
				this.formValues.branchCode = '';
				this.filterBranchList = [];
			}
		},        
		getFilterBankList(query) {
			if (query !== '') {
				this.loading = true;
				setTimeout(() => {
					this.loading = false;
					this.filterBankList = this.bankList.filter((item) => {
						return this.toFullWidth(item.label).toLowerCase().indexOf(this.toFullWidth(query).toLowerCase()) > -1;
					});
				}, 200);
			} else {
				this.options = [];
			}
		},
		getFilterBranchList(query) {
			if (query !== '') {
				this.loading = true;
				setTimeout(() => {
					this.loading = false;
					this.filterBranchList = this.branchList.filter((item) => {
						return this.toFullWidth(item.label).toLowerCase().indexOf(this.toFullWidth(query).toLowerCase()) > -1;
					});
				}, 200);
			} else {
				this.options = [];
			}
		},
		toFullWidth(input) {
			return input.replace(/[!-~]/g, (s) => {
		        return String.fromCharCode(s.charCodeAt(0)+0xFEE0);
        	});
		},        
        selectBank () {
            let retBankData = this.filterBankList.filter((item) => {
                return item.value == this.formValues.bankCode;
            });
            let retBranchData = this.filterBranchList.filter((item) => {
                return item.value == this.formValues.branchCode;
            });
            this.$emit('click', [retBankData[0], retBranchData[0]]);
        }
    },
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getCommonData: 'commonListManager/getCommonData',
		})
	}
};

</script>

<style lang='scss' scoped>

	.input-form {
		&__row {
			@apply flex items-center mb-3;
			> span {
				@apply flex-none w-72;
			}
			> div {
				@apply flex-grow;
			}
		}
	}

</style>
