<template>
	<div class='root-wrap'>
		<router-view/>
	</div>
</template>

<script>
export default {
	name: 'Root'
};
</script>

<style lang='scss' scoped>

</style>
