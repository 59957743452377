<template>
	<div>
		<div class='page-header'>
			<div>計画中止</div>
			<div>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>採択済み申請一覧</span>
			</div>
			<el-card class='w-full mb-10' shadow='never'> 
				<div>
					<div class='flex mb-5'>
						<div class='flex-grow justify-start'>
							<el-pagination
								background
								@current-change='currentChange'
								:current-page.sync='currentPage'
								:page-size='listSize'
								layout='total, prev, pager, next'
								:total='uploadListCount' />
						</div>
						<div class='flex-none w-32 justify-end text-right'>
							<el-button size='mini' icon='el-icon-sort' plain type='primary' @click.stop='dialogVisible.sort=true'>並び替え</el-button>
						</div>
					</div>
					<div>
						<el-table
							:data="uploadList"
							style="width: 100%"
							row-key="id"
							lazy
							:tree-props="{children: 'children', hasChildren: 'hasChildren'}"
						>
							<el-table-column
								align='center'
								type='index'
								width='50'
								label='No'
								:index='(currentPage-1)*10+1'
							/>
							<el-table-column prop='apply_code' label='受付番号' align='center' width='100' />
							<el-table-column prop='prefecture' label='都道府県' width='120' />
							<el-table-column prop='shokokai' label='商工会' width='250' />
							<el-table-column prop='kbn' label='区分' width='150' />
							<el-table-column prop='business_practitioner' label='事業実施者' />
							<el-table-column align='center' label='アップロード状況'>
								<el-table-column align='center' prop='yoshikidai5' label='様式第5' width='150' />
							</el-table-column>
							<el-table-column prop='cancel_number' label='番号' width='150' />
							<el-table-column align='center' fixed='right' label='中止申請' width='100'>
								<template slot-scope='scope'>
									<el-button
										type='primary'
										plain
										size='mini'
										@click="transitionDetail(scope.row)"
										icon='el-icon-edit'
										:disabled='disabled'
									/>
								</template>
							</el-table-column>					
						</el-table>
					</div>
				</div>
			</el-card>
		</div>

		<!-- 並び替え項目選択ダイアログ Start -->
		<el-dialog class='dialog-logout' title='並び替え' :visible.sync='dialogVisible.sort' >
			並び替え項目を選択してください
			<div class='p-4'>
				<el-radio v-model='sortType' label='1'>都道府県、商工会順</el-radio>
				<el-radio v-model='sortType' label='2'>中止申請順</el-radio>
			</div>
			<div slot='footer'>
				<el-button @click.stop='dialogVisible.sort=false'>キャンセル</el-button>
				<el-button type='primary' @click.stop='getCancelList'>並び替えを行う</el-button>
			</div>
		</el-dialog>
		<!-- 並び替え項目選択ダイアログ End -->

	</div>
</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT = '/cancel';

export default {
	name: 'Cancel',
	data () {
    	return {
			currentPage: 0,
			listSize: 10,
			uploadListCount: 0,
			uploadList: [],
			dialogVisible: {
				modification: false,
				sort: false,
			},
			selectRowData: {},
			disabled: true,
			sortType: '1'
    	};
	  },
	components: {
	},
	created () {
	},
	mounted () {
		this.$nextTick(function () {
			this.$emit('selectPage', '2');
			this.disabled = this.getFaseStatus['4'];
			let userInfo = {
				prefecture_name: '',
				shokokai_name: '',
				target_kbn_name: '',
			}
//			this.$store.dispatch('uploadInfoManager/setUploadInfo', userInfo);

			this.getCancelList();
		});
	},
	methods: {
		async getCancelList () {

			// ローディング開始
			this.startLoading('一覧を取得しています。しばらくおまちください。');
			try {
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_cancel_list',
					params: JSON.stringify({
						prefecture_code: this.getUserStatus.prefectureCode,
						shokokai_code: this.getUserStatus.shokokaiCode,
						offering_code: this.getUserStatus.offeringCode,
						authority: this.getUserStatus.authority,
						offset: this.listSize * (this.currentPage - 1),
						limit: this.listSize,
						sort_type: this.sortType,
					})
				});
				this.uploadList = result.data.data;
				this.uploadListCount = result.data.count;
				
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
				this.dialogVisible.sort = false;
			}
		},
		currentChange () {
			this.getCancelList();
		},
		transitionDetail (row) {
			let userInfo = {
				prefecture_name: (row.prefecture_name ? row.prefecture_name : ''),
				shokokai_name: (row.j_shokokai_name ? row.j_shokokai_name : ''),
				target_kbn_name: (row.target_kbn_name ? '(' + row.target_kbn_name + ')' : ''),
			}
			this.$store.dispatch('uploadInfoManager/setUploadInfo', userInfo);
			this.$router.push({ name: 'cancelDetail', params: { auth: true, id: row.apply_info_id, data: { businessPractitioner: row.business_practitioner } }}).catch(err => {});
		},
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getFaseStatus: 'faseStatusManager/getFaseStatus',			
		}),
	}
};
</script>

<style lang='scss' scoped>

</style>
