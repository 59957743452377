export default {
    data () {
        return {
			messages: {
				'ADD_JOINT_COMPANY': {
					'OK': {
						'message': '共同実施者を追加しました。',
						'type': 'success'
					},
					'NG': {
						'message': '共同実施者の追加に失敗しました。',
						'type': 'error'
					},
					'ALREADY_ADD_ORGANIZATION': {
						'message': '既に申請されている組織です。',
						'type': 'error'
					},
				},
				'DELETE_JOINT_COMPANY': {
					'OK': {
						'message': '共同実施者を削除しました。',
						'type': 'success'
					},
					'NG': {
						'message': '共同実施者の削除に失敗しました。',
						'type': 'error'
					},
				},
				'UPLOAD_APPLY': {
					'REGIST': {
						'message': '申請情報の登録が完了しました。',
						'type': 'success'
					},
					'REGIST_WITH_UPLOAD': {
						'message': '申請情報の登録が完了しました。申請様式ファイルの登録結果は「登録処理結果.xlsx」をご確認ください。',
						'type': 'success'
					},
					'CHECK_ERROR': {
						'message': '申請情報の登録時にエラーが発生しました。詳細はエラーリストを確認してください。',
						'type': 'error'
					},
					'DELETE': {
						'message': '申請情報の削除が完了しました。',
						'type': 'success'
					},
				},
				'JUDGE_ALLOCATION': {
					'OK': {
						'message': '審査員の登録が完了しました。',
						'type': 'success'
					},
					'NG': {
						'message': '審査員の登録に失敗しました。',
						'type': 'success'
					}
				},
				'DISCRIMINATION_SCORE': {
					'OK': '採択結果の自動判別が完了しました。',
					'NG': '採択結果の自動判別に失敗しました。',
				},
				'ERROR': {
					'WEB_API_ERROR': {
						'message': '処理中にエラーが発生しました。システム管理者にご連絡ください。',
						'type': 'error'
					},
				}			
			}
        };
    },
    methods: {
		showMessage (messageId, detail) {
			this.$message({
				message: this.messages[messageId][detail].message,
				type: this.messages[messageId][detail].type,
				duration: 5000
			});
		},
		showResultMessage (result, messageId) {
            if (result.result === 'OK') {
				this.$message({
					message: this.messages[messageId][result.detail],
					type: 'success',
					duration: 5000
				});
            } else {
				this.$message({
					message: this.messages[messageId][result.detail],
					type: 'error',
					duration: 5000
				});
			}
		}
    },
    computed: {
    }
};
