<template>
	<div class='h-full'>
		<div class='page-header'>
			<div>ポータル</div>
			<div>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>進捗状況</span>
				<span>{{ this.formatDate(new Date(), 'YYYY年MM月DD日 時点') }}</span>
			</div>
			<el-card class='w-full mb-8' shadow='never'> 
				<div>
					<el-table
						class='table-class'
						:data="tableData"
						stripe
						>
						<el-table-column
							prop="fase_name"
							label="内容"
							width="100px">
						</el-table-column>
						<el-table-column
							prop="from_date"
							label="開始日"
							width="150px">
						</el-table-column>
						<el-table-column
							prop="to_date"
							label="終了日"
							width="150px">
						</el-table-column>
						<el-table-column
							prop="summary"
							label='サマリ'
							>
						</el-table-column>
						<el-table-column
							align='center'
							fixed='right'
							label='状況'
							width='100'>
							<template slot-scope='scope'>
								<el-tag
									:type='statusTab(scope.row.fase_status)'
									disable-transitions>{{scope.row.status_name}}
								</el-tag>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-card>
<!--			
			<div class='section-header'>
				<span>お知らせ</span>
			</div>
			<el-card class='w-full' shadow='never'> 
				<div>
					<el-table
						class='table-class'
						:data="tableData"
						stripe
						>
						<el-table-column
							prop="content"
							label="内容"
							width="100px">
						</el-table-column>
						<el-table-column
							prop="startDate"
							label="開始日"
							width="150px">
						</el-table-column>
						<el-table-column
							prop="endDate"
							label="終了日"
							width="150px">
						</el-table-column>
						<el-table-column
							prop="summary"
							label='サマリ'
							>
						</el-table-column>
						<el-table-column
							align='center'
							fixed='right'
							prop='status'
							label='状況'
							width='100'>
							<template slot-scope='scope'>
								<el-tag
									:type='statusTab(scope.row.status)'
									disable-transitions>{{scope.row.name}}
								</el-tag>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-card>
-->			
		</div>
	</div>

</template>

<script>

const API_ENDPOINT = '/portal';
const API_ENDPOINT_OFFERING = '/offering';

import { mapGetters } from 'vuex';

export default {
	name: '',
	data () {
    	return {
			tableData: []		
    	};
	  },
	components: {
	},
	created () {
	},
	mounted () {
		this.doInit();
	},
	methods: {
		async doInit() {
			let offeringStatusList = await this.$ajaxPlugin.post(API_ENDPOINT_OFFERING, { action: 'get_offering_list', params: JSON.stringify({ offering_code: this.getUserStatus.offeringCode })});
			this.tableData = offeringStatusList.data.data;
		},
		statusTab (status) {
			switch (status) {
				case '2':
					return 'info';
				case '1':
					return 'success';
				case '0':
					return 'warning';
				default:
					return '';
			}
		}
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getCommonData: 'commonListManager/getCommonData',
		})
	}	
};
</script>

<style lang='scss' scoped>

</style>
