<template>
	<div>
		<div class='page-header'>
			<div>採択結果選択</div>
			<div class='flex justify-center items-center'>
				ボーダーライン点数：
				<div class='w-10 mr-3'>
					<el-input size='mini' v-model='borderScore' :disabled='disabled' />
				</div>
				<el-button 
					size='small' 
					plain 
					type='primary' 
					:disabled='disabled' 
					@click.stop='discriminationConfirm'
				>採択結果自動判別</el-button>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>補助対象事業者別 採択結果一覧</span>
			</div>
			<el-card class='w-full mb-10' shadow='never'> 
				<div>
					<div class='flex mb-5'>
						<div class='flex-grow justify-start'>
							<el-pagination
								background
								@current-change='currentChange'
								:current-page.sync='currentPage'
								:page-size='listSize'
								layout='total, prev, pager, next'
								:total='adoptedResultListCount' />
						</div>
						<div class='flex-none w-32 justify-end text-right'>
							<el-button size='mini' icon='el-icon-sort' plain type='primary' @click.stop='dialogVisible.sort=true'>並び替え</el-button>
						</div>
					</div>
					<div>
						<el-table
							:data='adoptedResultList'
							:row-class-name='tableRowClassName'
							style="width: 100%"
							row-key="id"
							lazy
						>
							<el-table-column
								align='center'
								type='index'
								width='50'
								label='No'
								:index='(currentPage-1)*10+1'
							/>
							
							<el-table-column prop='apply_code' label='受付番号' align='center' width='100' />
							<el-table-column prop='prefecture' label='都道府県' width='150' />
							<el-table-column prop='shokokai' label='商工会' width='250' />
							<el-table-column prop='kbn' label='区分' width='150' />
							<el-table-column prop='business_practitioner' label='事業実施者' />
							<el-table-column align='center' prop='adjustment_evaluation_score' label='甘辛結果採点' width='180' />
							<el-table-column align='center' fixed='right' label='採択結果' width='120'>
								<template slot-scope='scope'>
									<el-select :disabled='checkDisabled(scope.row)' size='small' v-model='scope.row.adoption_result_kbn' @change='setAdoptionResult(scope.row)'>
										<el-option v-for='item in list' :key='item.value' :label='item.label' :value='item.value' />
									</el-select>										
								</template>
							</el-table-column>					
						</el-table>
					</div>
				</div>		
			</el-card>
		</div>		

		<!-- 採択結果自動判別確認ダイアログ Start -->
		<el-dialog class='dialog-logout' title='採択結果 自動判別確認' :visible.sync='dialogVisible.discrimination' >
			<div v-if='unmatchCount >= 1'>
				過去に採択結果が設定されています。
			</div>
			{{ borderScore }}をボーダーライン点数として採択結果を自動判別します。<br/>よろしいですか。
			<div slot='footer'>
				<el-button type='primary' @click.stop='discriminationScore'>はい</el-button>
				<el-button @click.stop='dialogVisible.discrimination=false'>いいえ</el-button>
			</div>
		</el-dialog>
		<!-- 採択結果自動判別確認ダイアログ End -->

		<!-- 並び替え項目選択ダイアログ Start -->
		<el-dialog class='dialog-logout' title='並び替え' :visible.sync='dialogVisible.sort' >
			並び替え項目を選択してください
			<div class='p-4'>
				<el-radio v-model='sortType' label='1'>都道府県、商工会順</el-radio>
				<el-radio v-model='sortType' label='2'>採択結果、甘辛評価点順</el-radio>
				<el-radio v-model='sortType' label='3'>申請番号順</el-radio>
			</div>
			<div slot='footer'>
				<el-button @click.stop='dialogVisible.sort=false'>キャンセル</el-button>
				<el-button type='primary' @click.stop='getAdoptionResultList'>並び替えを行う</el-button>
			</div>
		</el-dialog>
		<!-- 並び替え項目選択ダイアログ End -->

	</div>
</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT = '/adoption-result';

export default {
	name: 'AdoptionResult',
	props: {
	},
	data () {
    	return {
			currentPage: 0,
			listSize: 10,
			adoptedResultList: [],
			adoptedResultListCount: 0,
			list: [
				{ label: '未選択', value: '0' },
				{ label: '採択', value: '1' },
				{ label: '不採択', value: '2' },
			],
			disabled: true,
			borderScore: '',
			dialogVisible: {
				discrimination: false,
				sort: false,			
			},
			unmatchCount: 0,
			sortType: '1'
    	};
	},
	components: {
	},
	created () {
	},
	mounted () {
		this.$nextTick(function () {
			this.disabled = this.getFaseStatus['2'];
			this.getAdoptionResultList();
		});
	},
	methods: {
		async getAdoptionResultList () {
			// ローディング開始
			this.startLoading('一覧を取得しています。しばらくおまちください。');
			try {
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'adoption_result_list',
					params: JSON.stringify({
						prefecture_code: this.getUserStatus.prefectureCode,
						shokokai_code: this.getUserStatus.shokokaiCode,
						authority: this.getUserStatus.authority,
						offering_code: this.getUserStatus.offeringCode,
						offset: this.listSize * (this.currentPage - 1),
						limit: this.listSize,
						sort_type: this.sortType
					})
				});
				this.adoptedResultList = result.data.data;
				this.adoptedResultListCount = result.data.count;

			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
				this.dialogVisible.sort = false;
			}
		},
		currentChange () {
			this.getAdoptionResultList();
		},
      	tableRowClassName({row, rowIndex}) {
			if (row.adoption_result_kbn === '1') {
				return 'success-row';
			} else if (row.adoption_result_kbn === '2') {
				return 'warning-row';
			}
			return '';
		},
		async setAdoptionResult (row) {
			let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
				action: 'set_adoption_result',
				params: JSON.stringify({
					id: row.apply_info_id,
					adoption_result_kbn: row.adoption_result_kbn,
				})
			});
			this.dialogVisible.discrimination = false;
		},
		async discriminationConfirm () {
			if (this.borderScore >= 1 && this.borderScore <= 90) {
				// 採択結果とボーダーライン点数のアンマッチが存在しないか確認する
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'border_score_unmatch_check',
					params: JSON.stringify({
						offering_code: this.getUserStatus.offeringCode,
						border_score: this.borderScore,
					})
				});
				if (result.data.result == 'OK') {
					this.unmatchCount = result.data.count;
					this.dialogVisible.discrimination = true;
				}
			} else {
				this.$message({
					message: 'ボーダーライン点数は1点以上90点以内で設定してください',
					type: 'error'
				});
			}
		},
		async discriminationScore () {
			try {
				// 採択結果の自動判別を行う
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'set_adoption_result_discrimination',
					params: JSON.stringify({
						offering_code: this.getUserStatus.offeringCode,
						border_score: this.borderScore,
					})
				});
				this.showResultMessage(result.data, 'DISCRIMINATION_SCORE');
				if (result.data.result == 'OK') {
					this.getAdoptionResultList();
				}
			} catch (e) {
				this.$logger.error(e.message);
			} finally {
				this.dialogVisible.discrimination = false;
			}
		},
		checkDisabled (row) {
			try {
				if (!this.getFaseStatus['2']){
					if (row.adjustment_evaluation_score) {
						// 甘辛評価点が設定されていない場合は無効とする
						return false;
					} else {
						return true;
					}
				} else {
					// 機能制限がかかっている場合は無条件に無効とする
					return true;
				}
			} catch (e) {
				this.$logger.error(e.message);
			}
		},
		showIndex (index) {
			console.log(index);
		}
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getFaseStatus: 'faseStatusManager/getFaseStatus',			
		}),
	}

};
</script>

<style lang='scss' scoped>

</style>
