<template>
	<div>
		<div class='page-header'>
			<div>採点表取り込み</div>
			<div>
				<el-button
					size='small'
					plain
					type='primary'
					icon='el-icon-upload2'
					@click.stop='dialogVisible.scoringResultImport=true'
					:disabled='disabled'
				>
					採点表取り込み
				</el-button>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>審査員別 回答結果一覧</span>
			</div>			
			<el-card class='w-full mb-10' shadow='never'> 
				<div>
					<div class='mb-5'>
						<el-pagination
							background
							@current-change='currentChange'
							:current-page.sync='currentPage'
							:page-size='listSize'
							layout='total, prev, pager, next'
							:total='scoringResultListCount' />
						</div>
					<div>
                        <el-table
                            :data="scoringResultList"
                            style="width: 100%"
                            row-key="id"
                            lazy
                        >
                            <el-table-column align='center' type='index' width='50' label='No' :index='(currentPage-1)*10+1' />
                            <el-table-column prop='kojin_no' label='個人No' width='100' />				
                            <el-table-column prop='name' label='氏名' />				
                            <el-table-column label='都道府県' width='120'>
                                <el-table-column prop='prefecture_code' label='コード' width='80' />
                                <el-table-column prop='prefecture_name' label='名称' width='120' />
                            </el-table-column>
                            <el-table-column prop='request_count' label='依頼件数' width='80' />
                            <el-table-column prop='result_count' label='回答件数' width='80' />
                        </el-table>		
					</div>
				</div>		
			</el-card>
		</div>

		<el-dialog title='採点表取り込み' :visible.sync='dialogVisible.scoringResultImport'>
			<div>
				<div class='w-full h-32 mb-5'>
					<file-upload-form :multiple='true' @drop='dropFile($event)' @select='selectFile($event)' />
				</div>
				<div class='w-full h-64 border px-3 overflow-auto mb-5'>
					<div class='p-2' v-for='(file, index) in fileList' :key='index'>
						{{ file.name }}
					</div>
				</div>
				<div slot='footer' class='w-full'>
					<el-button class='w-full' @click.stop='handleUpload' type='primary' size='small'>取り込み</el-button>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import FileUploadForm from '@/components/custom/FileUpdateForm';

const API_ENDPOINT = '/scoring-result-import';

export default {
	name: 'ScoringResultImport',
	data () {
    	return {
			currentPage: 0,
			listSize: 10,
			scoringResultListCount: 0,
			scoringResultList: [],
			dialogVisible: {
				scoringResultImport: false,
			},
			fileList: [],
			disabled: true,
		};
	},
	components: {
		'file-upload-form': FileUploadForm,
	},
	created () {
	},
	mounted () {
		this.$nextTick(function () {
			this.disabled = this.getFaseStatus['2'];
			let userInfo = {
				prefecture_name: '',
				shokokai_name: '',
				bu_kbn_name: '',
			}
//			this.$store.dispatch('uploadInfoManager/setUploadInfo', userInfo);

			this.getScoringResultImportList();
		});
	},
	methods: {
		async getScoringResultImportList () {
			// ローディング開始
			this.startLoading('一覧を取得しています。しばらくおまちください。');
			try {
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_scoring_result_import_list',
					params: JSON.stringify({
						offeringCode: this.getUserStatus.offeringCode,
						offset: this.listSize * (this.currentPage - 1),
						limit: this.listSize
					})
				});
				this.scoringResultList = result.data.data;
				this.scoringResultListCount = result.data.count;
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		currentChange () {
			this.getScoringResultImportList();
		},
		dropFile (files) {
			this.fileList = files;
		},
		selectFile (files) {
			//console.dir(files);
			this.fileList = files;
		},
		async handleUpload () {

			if (this.fileList.length <= 0) {				
				console.log('ファイルが選択されていません。');
				return false;
			};
            this.startLoading('採点表をアップロードしています。しばらくおまちください。');
			try {
				let params = new FormData();
				params.append('action', 'upload');                

				Array.from(this.fileList).forEach((file) => {
                //this.fileList.forEach((file) => {
                    params.append('fileList[]', file);
                })
				params.append('params', JSON.stringify({
					'offeringCode': this.getUserStatus.offeringCode,
				}));
//				let result = await this.$ajaxPlugin.post(API_ENDPOINT, params);
				let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
				this.downloadCsvBlob(response.data, 'report.xlsx');
				this.fileList = [];
				this.getEvaluationScoreCaptureList();
/*
				// 処理結果メッセージの表示
				this.showResultMessage(result.data, 'UPLOAD_APPLICATION');
				if (result.data.result == 'OK') {
					// 正常終了の場合
					this.getEvaluationScoreCaptureList();
				} else {
					// チェックエラーの場合
					if (result.data.detail == 'CHECK_ERROR') {
					}
				}                
*/
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				this.dialogVisible.evaluationScoreCapture = false;
				// ローディング停止
				this.stopLoading();
			}
        },

	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getFaseStatus: 'faseStatusManager/getFaseStatus',			
		}),
	}
};
</script>

<style lang='scss' scoped>

</style>
