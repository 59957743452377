<template>
	<div>
		<div class='page-header'>
			<div>申請様式登録</div>
			<div>
				<el-button 
					size='small' 
					icon='el-icon-circle-plus-outline' 
					plain 
					type='primary' 
					@click.stop='dialogVisible.create=true'
					:disabled='disabled'
				>新規登録
				</el-button>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>申請一覧</span>
			</div>
			<el-card class='w-full mb-10' shadow='never'> 
				<div>
					<div class='mb-5'>
						<el-pagination
							background
							@current-change='currentChange'
							:current-page.sync='currentPage'
							:page-size='listSize'
							layout='total, prev, pager, next'
							:total='uploadListCount' />
					</div>
					<div>
						<el-table
							:data="uploadList"
							style="width: 100%"
							row-key="apply_info_id"
							lazy
							:tree-props="{children: 'children', hasChildren: 'hasChildren'}"
						>
							<el-table-column
								align='center'
								type='index'
								width='50'
								label='No'
								:index='(currentPage-1)*10+1'
							/>
							<el-table-column prop='apply_code' label='受付番号' align='center' width='100' />
							<el-table-column prop='prefecture' label='都道府県' width='150' />
							<el-table-column prop='shokokai' label='商工会' width='250' />
							<el-table-column prop='kbn' label='区分' width='150' />
							<el-table-column prop='business_practitioner' label='事業実施者' />
							<el-table-column label='アップロード状況'>
								<el-table-column align='center' prop='yoshikidai1' label='様式第1' width='80' />
								<el-table-column align='center' prop='yoshiki1' label='様式1' width='80' />
								<el-table-column align='center' prop='yoshiki2' label='様式2' width='80' />
								<el-table-column align='center' prop='yoshiki3' label='様式3' width='80' />
							</el-table-column>
							<el-table-column align='center' fixed='right' label='修正' width='100'>
								<template slot-scope='scope'>
									<el-button
										type='primary'
										plain
										size='mini'
										@click="updateApply(scope.row)"
										icon='el-icon-edit'
										:disabled='disabled'
									/>
								</template>
							</el-table-column>					
							<el-table-column align='center' fixed='right' label='削除' width='100'>
								<template slot-scope='scope'>
									<el-button
										type='primary'
										plain
										size='mini'
										@click="showDeleteApplyDialog(scope.$index, scope.row)"
										icon='el-icon-delete'
										:disabled='disabled'
									/>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-card>
		</div>

		<!-- 新規登録ダイアログ Start -->
		<el-dialog title='新規登録' :visible.sync='dialogVisible.create' width='700px'>
			<select-organization :mode='"create"' @click='transitionDetail' />
		</el-dialog>
		<!-- 新規登録ダイアログ End -->

		<!-- 削除確認ダイアログ Start -->
		<el-dialog class='dialog-logout' title='データの削除' :visible.sync='dialogVisible.delete' width='300px' >
			削除します。よろしいですか？
			<div slot='footer'>
				<el-button type='primary' @click.stop='deleteApply'>はい</el-button>
				<el-button @click.stop='dialogVisible.delete=false'>いいえ</el-button>
			</div>
		</el-dialog>
		<!-- 削除確認ダイアログ End -->

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import SelectOrganization from '@/components/dialog/SelectOrganization';

const API_ENDPOINT = '/apply-form';

export default {
	name: 'ApplyForm',
	data () {
    	return {
			currentPage: 0,
			listSize: 10,
			uploadListCount: 0,
			uploadList: [],
			dialogVisible: {
				create: false,
				delete: false,
			},
			selectRowData: {},
			disabled: true,
    	};
	  },
	components: {
		'select-organization': SelectOrganization
	},
	created () {
	},
	mounted () {
		this.$nextTick(function () {			
			this.$emit('selectPage', '1');
			this.disabled = this.getFaseStatus['1'];
			let userInfo = {
				prefecture_name: '',
				shokokai_name: '',
				target_kbn_name: '',
			}
//			this.$store.dispatch('uploadInfoManager/setUploadInfo', userInfo);

			this.getApplyList();
		});
	},
	methods: {
		async getApplyList () {
			// ローディング開始
			this.startLoading('一覧を取得しています。しばらくおまちください。');
			try {
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_apply_list',
					params: JSON.stringify({
						prefecture_code: this.getUserStatus.prefectureCode,
						shokokai_code: this.getUserStatus.shokokaiCode,
						offering_code: this.getUserStatus.offeringCode,
						authority: this.getUserStatus.authority,
						offset: this.listSize * (this.currentPage - 1),
						limit: this.listSize
					})
				});
				this.uploadList = result.data.data;
				this.uploadListCount = result.data.count;
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		currentChange () {
			this.getApplyList();
		},
		updateApply (row) {
			let userInfo = {
				prefecture_name: (row.prefecture_name ? row.prefecture_name : ''),
				shokokai_name: (row.j_shokokai_name ? row.j_shokokai_name : ''),
				target_kbn_name: (row.target_kbn_name ? '(' + row.target_kbn_name + ')' : ''),
			}
			this.$store.dispatch('uploadInfoManager/setUploadInfo', userInfo);
			this.$router.push({ name: 'applyFormDetail', params: { auth: true, id: row.apply_info_id, data: { businessPractitioner: row.business_practitioner } }});
		},
		showDeleteApplyDialog(index, row) {
			// this.applicationId = row.id;
			this.selectRowData = row;
			this.dialogVisible.delete = true;
		},
		async deleteApply () {
			// ローディング開始
			this.startLoading('申請情報を削除しています。しばらくおまちください。');
			try {
				// ファイルアップロード情報の削除
				let response = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'delete_apply_data',
					params: JSON.stringify({
						id: this.selectRowData.apply_info_id,
						offering_code: this.getUserStatus.offeringCode,
						prefecture_code: this.selectRowData.prefecture_code,
						shokokai_code: this.selectRowData.shokokai_code,
						target_kbn: this.selectRowData.target_kbn,
					})
				});

				this.showMessage('UPLOAD_APPLY', 'DELETE');
				this.dialogVisible.delete = false;
				this.getApplyList();			

			} catch (e) {
				this.$logger.error(e.message);
				this.showMessage('ERROR', 'WEB_API_ERROR');
			} finally {
				// ローディング停止
				this.stopLoading();
			}

		},
		transitionDetail (values) {
			values['businessPractitioner'] = ''
			this.$router.push({ name: 'applyFormDetail', params: { auth: true, id: '', data: values }});
		}
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getFaseStatus: 'faseStatusManager/getFaseStatus',			
		}),
	}
};
</script>

<style lang='scss' scoped>

</style>
