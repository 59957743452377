<template>
	<div>
		<div class='page-header'>
			<div>甘辛評価表取込</div>
			<div>
				<el-button
					size='small'
					plain
					type='primary'
					icon='el-icon-upload2'
					@click.stop='dialogVisible.adjustmentEvaluationImport=true'
					:disabled='disabled'
				>
					評価表取り込み
				</el-button>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>補助対象事業者別 評価点一覧</span>
			</div>
			<el-card class='w-full mb-10' shadow='never'> 
				<div>
					<div class='mb-5'>
						<el-pagination
							background
							@current-change='currentChange'
							:current-page.sync='currentPage'
							:page-size='listSize'
							layout='total, prev, pager, next'
							:total='adjustmentEvaluationListCount' />
					</div>
					<div>
						<el-table
							:data="adjustmentEvaluationList"
							style="width: 100%"
							row-key="id"
							lazy
						>
							<el-table-column
								align='center'
								type='index'
								width='50'
								label='No'
								:index='(currentPage-1)*10+1'								
							/>
							<el-table-column prop='apply_code' label='受付番号' align='center' width='100' />
							<el-table-column prop='prefecture' label='都道府県' width='150' />
							<el-table-column prop='shokokai' label='商工会' width='250' />
							<el-table-column prop='business_practitioner' label='補助対象者' />
							<el-table-column align='center' prop='total1' label='審査員A' width='150' />
							<el-table-column align='center' prop='total2' label='審査員B' width='150' />
							<el-table-column align='center' prop='total3' label='審査員C' width='150' />
							<el-table-column align='center' fixed='right' label='甘辛評価点' width='180'>
								<template slot-scope='scope'>
									{{ scope.row.adjustment_evaluation_score }}
								</template>
							</el-table-column>					
						</el-table>
					</div>
				</div>		
			</el-card>
		</div>

		<el-dialog title='甘辛審査結果取り込み' :visible.sync='dialogVisible.adjustmentEvaluationImport'>
			<div>
				<div class='w-full h-32 mb-5'>
					<file-upload-form ref='upload' :multiple='true' @drop='dropFile($event)' @select='selectFile($event)' />
				</div>
				<div class='w-full h-64 border px-3 overflow-auto mb-5'>
					<div class='p-2' v-for='(file, index) in fileList' :key='index'>
						{{ file.name }}
					</div>
				</div>
				<div slot='footer' class='w-full'>
					<el-button class='w-full' @click.stop='handleUpload' type='primary' size='small'>取り込み</el-button>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import SelectJudge from '@/components/dialog/SelectJudge';
import FileUploadForm from '@/components/custom/FileUpdateForm';

const API_ENDPOINT = '/adjustment-evaluation-import';

export default {
	name: 'AdjustmentEvaluationImport',
	data () {
    	return {
			currentPage: 0,
			listSize: 10,
			adjustmentEvaluationListCount: 0,
			adjustmentEvaluationList: [],
			dialogVisible: {
				adjustmentEvaluationImport: false,
			},
			param: {
				uploadFileId: '',
				prefectureCode: ''
			},
			disabled: true,
			fileList: [],
    	};
	},
	components: {
		'select-judge': SelectJudge,
		'file-upload-form': FileUploadForm,
	},
	created () {
	},
	mounted () {
		this.$nextTick(function () {
			this.disabled = this.getFaseStatus['2'];
			this.getAdjustmentEvaluationList();
		});
	},
	methods: {
		async getAdjustmentEvaluationList () {
			// ローディング開始
			this.startLoading('一覧を取得しています。しばらくおまちください。');
			try {
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_adjustment_evaluation_list',
					params: JSON.stringify({
						offering_code: this.getUserStatus.offeringCode,
						offset: this.listSize * (this.currentPage - 1),
						limit: this.listSize
					})
				});
				this.adjustmentEvaluationList = result.data.data;
				this.adjustmentEvaluationListCount = result.data.count;
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		currentChange () {
			this.getAdjustmentEvaluationList();
		},
		dropFile (files) {
			this.fileList = files;
		},
		selectFile (files) {
			this.fileList = files;
		},
		async handleUpload () {
			if (this.fileList.length <= 0) {				
				console.log('ファイルが選択されていません。');
				return false;
			};
            this.startLoading('甘辛審査結果をアップロードしています。しばらくおまちください。');
			try {
				let params = new FormData();
				params.append('action', 'import_evaluation_file');                

				Array.from(this.fileList).forEach((file) => {
                //this.fileList.forEach((file) => {
                    params.append('fileList[]', file);
                })
				params.append('params', JSON.stringify({
					'offeringCode': this.getUserStatus.offeringCode,
				}));
				let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
				if (response.data.size >= 1) {
					this.downloadCsvBlob(response.data, 'report.xlsx');
				}
				this.fileList = [];
				this.getAdjustmentEvaluationList();
				this.dialogVisible.adjustmentEvaluationImport = false;

			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				this.dialogVisible.evaluationScoreCapture = false;
				this.$refs['upload'].clearFile();
				// ローディング停止
				this.stopLoading();
			}
        },		
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getFaseStatus: 'faseStatusManager/getFaseStatus',
		}),
	}
};
</script>

<style lang='scss' scoped>

</style>
