<template>
	<div>
		<div class='page-header'>
			<div>申請様式登録：{{ editPatternName }}</div>
			<div>
			</div>
		</div>
		<div class='mb-5'>事業実施者：{{ this.$route.params.data.businessPractitioner }}</div>
		<div>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>申請情報</span>
				</div>
				<div class='input-form__row'>
					<span>申請日</span>
					<div>
						<el-date-picker
							v-model='formValues.applyDate'
							type="date"
							placeholder='選択してください'
							value-format='yyyy-MM-dd'
							size='small'>
						</el-date-picker>
					</div>
				</div>
				<div class='input-form__row'>
					<span>補助事業の開始日および完了予定日</span>
					<div>
						<div>
							<span class='mr-3'>交付決定日</span>
							<span class='mr-3'>〜</span>
								<el-date-picker
								v-model='formValues.endDate'
								type="date"
								placeholder='選択してください'
								value-format='yyyy-MM-dd'
								size='small'>
							</el-date-picker>
						</div>
					</div>
				</div>
				<div class='input-form__row'>
					<span>補助事業に関して生ずる収入金に関する事項</span>
					<div>
						<div>
							<el-radio v-model='formValues.matterFlg' label='1'>あり</el-radio>
							<el-radio v-model='formValues.matterFlg' label='2'>なし</el-radio>
						</div>									
					</div>
				</div>
				<div class='input-form__row'>
					<span>※ありの場合、該当事項を入力してください</span>
					<div>
						<el-input
							type="textarea"
							:rows="2"
							v-model="formValues.matterContents">
						</el-input>								
					</div>
				</div>					

			</el-card>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header' class='flex justify-between'>
					<span>事業実施者情報</span>
					<div class='middle-right' v-if='this.formValues.applyType==2'>
						<div v-if='uploadFileId'>
							<el-button
								type='primary'
								size='mini' 
								@click.stop='showAddJointCompanyDialog'
							>共同実施者追加
							</el-button>
						</div>
					</div>

				</div>
				<div class='input-form__row'>
					<span>代表者名</span>
					<div>
						<el-input v-model='formValues.representativeName' size='small' />
					</div>
				</div>
				<div class='input-form__row'>
					<span>役職</span>
					<div>
						<el-input class='w-64' v-model='formValues.representativePosition' size='small' />
					</div>
				</div>
				<div class='input-form__row'>
					<span>会長名</span>
					<div>
						<el-input class='w-64' v-model='formValues.chairmanName' size='small' />
					</div>
				</div>
				<div class='input-form__row'>
					<span>住所</span>
					<div>
						<el-input v-model='formValues.address' size='small' />
					</div>
				</div>

			</el-card>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>申請様式ファイル選択</span>
				</div>
				<div class='upload-form'>
					<div class='upload-wrap'>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式第１</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai1' @drop='dropFile("yoshikiDai1", $event)' @select='selectFile("yoshikiDai1", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai1.id, uploadFileInfo.yoshikiDai1.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai1.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai1.lastUpdate }}</div>
						</div>
					</div>
					<div class='upload-wrap'>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式１</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshiki1' @drop='dropFile("yoshiki1", $event)' @select='selectFile("yoshiki1", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshiki1.id, uploadFileInfo.yoshiki1.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshiki1.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshiki1.lastUpdate }}</div>
						</div>
					</div>
						<div class='attachment mt-3 mb-3' v-if='this.formValues.applyType==2'>
							<div>
								<div>
									<el-checkbox v-model='formValues.attachment'>様式１（別添）が添付されていることを確認</el-checkbox>
								</div>
							</div>
						</div>

					<div class='upload-wrap'>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式２</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshiki2' @drop='dropFile("yoshiki2", $event)' @select='selectFile("yoshiki2", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshiki2.id, uploadFileInfo.yoshiki2.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshiki2.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshiki2.lastUpdate }}</div>
						</div>
					</div>
					<div class='upload-wrap'>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式３</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshiki3' @drop='dropFile("yoshiki3", $event)' @select='selectFile("yoshiki3", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshiki3.id, uploadFileInfo.yoshiki3.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshiki3.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshiki3.lastUpdate }}</div>
						</div>
					</div>
				</div>
			</el-card>
			<el-button type='primary' class='mb-8 w-full' @click.stop='registApplyData'>登録する</el-button>			
		</div>

		<el-dialog title='入力エラー' :visible.sync='dialogVisible.inputError'>
			<div class='p-2' v-for='(item, index) in errorList' :key='index'>
				{{ item }}
			</div>
		</el-dialog>

		<el-dialog title='共同実施者追加' :visible.sync='dialogVisible.addJointCompany' width='800px'>
			<add-joint-company ref='addJointCompany' v-model='uploadFileId' />
		</el-dialog>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import FileUploadForm from '@/components/custom/FileUpdateForm';
import AddJointCompany from '@/components/dialog/AddJointCompany';

const API_ENDPOINT = '/apply-form-detail';

export default {
	name: 'ApplyFormDetail',
	props: {
		params: {},
		value: {}
	},
	data () {
    	return {
			formValues: {
				applyType: '',
				prefectureCode: '',
				shokokaiCode: '',
				targetKbn: '',
				applyDate: '',
				endDate: '',
				matterFlg: '',
				matterContents: '',
				representativeName: '',
				representativePosition: '',
				chairmanName: '',
				address: '',
				attachment: false,
				adoptionResultKbn: '',
			},			
			fileList: {
				'yoshikiDai1': '',
				'yoshiki1': '',
				'yoshiki2': '',
				'yoshiki3': '',
			},
			uploadFileInfo: {
				'yoshikiDai1': {id: '', fileName: '', lastUpdate: ''},
				'yoshiki1': {id: '', fileName: '', lastUpdate: ''},
				'yoshiki2': {id: '', fileName: '', lastUpdate: ''},
				'yoshiki3': {id: '', fileName: '', lastUpdate: ''},
			},
			dialogVisible: {
				inputError: false,
				addJointCompany: false,
			},
			errorList: [],
			uploadFileId: '',
			isUpdate: false
    	};
	},
	components: {
		'file-upload-form': FileUploadForm,
		'add-joint-company': AddJointCompany
	},
	created () {
		// ローディング開始
		this.startLoading('データを取得しています。しばらくおまちください。');
		try {
			if (this.$route.params.id) {
				this.uploadFileId = this.$route.params.id;
			} else {
				this.formValues.applyType = this.$route.params.data.applyType;
				this.formValues.prefectureCode = this.$route.params.data.prefectureCode;
				this.formValues.shokokaiCode = this.$route.params.data.shokokaiCode;
				this.formValues.targetKbn = this.$route.params.data.targetKbn;
			}

			this.getApplyData();

		} catch (e) {
			this.$logger.error(e.message);
		} finally {
			// ローディング停止
			this.stopLoading();
		}
	},
	mounted () {
	},
	methods: {
		async getApplyData () {

			this.startLoading('データを取得しています。しばらくおまちください。');
			try {
				// ファイルアップロード情報の取得
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_apply_data',
					params: JSON.stringify({
						id: this.uploadFileId,
						prefectureCode: this.formValues.prefectureCode,
						shokokaiCode: this.formValues.shokokaiCode,
						targetKbn: this.formValues.targetKbn,
					})
				});
				if (result.data.result == 'OK') {
					this.formValues.applyDate = '';
					this.formValues.endDate = '';
					this.formValues.matterFlg = '';
					this.formValues.matterContents = '';
					this.formValues.representativeName = '';
					this.formValues.representativePosition = '';
					this.formValues.chairmanName = '';
					this.formValues.address = '';
					this.formValues.attachment = false;
					this.formValues.adoptionResultKbn = '';
					Object.keys(this.uploadFileInfo).forEach((key) => {
						this.$set(this.uploadFileInfo, key, {id: '', fileName: '', lastUpdate: ''});
					});
					// データが存在する場合は画面に反映させる
					if (result.data.data.length >= 1) {
						if (this.uploadFileId) {
							this.formValues.offeringCode = result.data.data[0].offering_code;
							this.formValues.applyType = result.data.data[0].apply_type;
							this.formValues.prefectureCode = result.data.data[0].prefecture_code;
							this.formValues.shokokaiCode = result.data.data[0].shokokai_code;
							this.formValues.targetKbn = result.data.data[0].target_kbn;
							this.formValues.applyDate = result.data.data[0].apply_date;
							this.formValues.endDate = result.data.data[0].end_date;
							this.formValues.matterFlg = result.data.data[0].matter_flg;
							this.formValues.matterContents = result.data.data[0].matter_contents;
							this.formValues.representativeName = result.data.data[0].representative_name;
							this.formValues.representativePosition = result.data.data[0].representative_position;
							this.formValues.chairmanName = result.data.data[0].chairman_name;
							this.formValues.address = result.data.data[0].address;
							this.formValues.attachment = result.data.data[0].attachment;
							this.formValues.adoptionResultKbn = result.data.data[0].adoption_result_kbn;
							result.data.data[0].yoshiki_info.forEach((value, index) => {
								switch (value.id_name) {
									case 'yoshikiDai1':
										this.uploadFileInfo.yoshikiDai1.id = value.id;
										this.uploadFileInfo.yoshikiDai1.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai1.lastUpdate = value.last_update;
										break;
									case 'yoshiki1':
										this.uploadFileInfo.yoshiki1.id = value.id;
										this.uploadFileInfo.yoshiki1.fileName = value.file_name;
										this.uploadFileInfo.yoshiki1.lastUpdate = value.last_update;
										break;
									case 'yoshiki2':
										this.uploadFileInfo.yoshiki2.id = value.id;
										this.uploadFileInfo.yoshiki2.fileName = value.file_name;
										this.uploadFileInfo.yoshiki2.lastUpdate = value.last_update;
										break;
									case 'yoshiki3':
										this.uploadFileInfo.yoshiki3.id = value.id;
										this.uploadFileInfo.yoshiki3.fileName = value.file_name;
										this.uploadFileInfo.yoshiki3.lastUpdate = value.last_update;
										break;
									default:
										break;
								}
							});
							this.isUpdate = true;
						} else {
							this.formValues.chairmanName = result.data.data[0].chairman_name;
							this.formValues.address = result.data.data[0].address;
							this.formValues.adoptionResultKbn = '0';
						}
					}
				}
			} catch (e) {
				this.$logger.error(e.message);			
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		async registApplyData () {
			if (!this.formCheck()) {
				this.dialogVisible.inputError = true;
				return false;
			}
			this.startLoading('ファイルをアップロードしています。しばらくおまちください。');

			try {
				let params = new FormData();
				params.append('action', 'regist_apply_data');
				
				Object.keys(this.fileList).forEach((key) => {
					if (this.fileList[key] != '') {
						params.append(key, this.fileList[key]);
					}
				});

				params.append('params', JSON.stringify({
					'offeringCode': this.getUserStatus.offeringCode,
					'applyType' : this.formValues.applyType,
					'prefectureCode' : this.formValues.prefectureCode,
					'shokokaiCode': this.formValues.shokokaiCode,
					'targetKbn': this.formValues.targetKbn,
					'applyDate': this.formValues.applyDate,
					'endDate': this.formValues.endDate,
					'matterFlg': this.formValues.matterFlg,
					'matterContents': this.formValues.matterContents,
					'userId': this.getUserStatus.userId,
					'representativeName': this.formValues.representativeName,
					'representativePosition': this.formValues.representativePosition,
					'chairmanName': this.formValues.chairmanName,
					'address': this.formValues.address,
					'attachment': this.formValues.attachment,
					'adoptionResultKbn': this.formValues.adoptionResultKbn,
				}));
	
				let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
				if (response.data.size >= 1) {
					this.downloadCsvBlob(response.data, '取込処理結果.xlsx');
					this.showMessage('UPLOAD_APPLY', 'REGIST_WITH_UPLOAD');
				} else {
					this.showMessage('UPLOAD_APPLY', 'REGIST');
				}
		
			} catch (e) {
				this.$logger.error(e.message);
				this.showMessage('ERROR', 'WEB_API_ERROR');
			} finally {
				// ローディング停止
				this.stopLoading();
				this.getApplyData();
			}
		},
		formCheck () {
			this.errorList = [];
			if (!this.formValues.targetKbn){
				this.errorList.push('【事業実施者（法人の内部組織）】を選択してください');
			}
			if (!this.formValues.applyDate){
				this.errorList.push('【申請日】を入力してください');
			}
			if (!this.formValues.endDate){
				this.errorList.push('【補助事業の開始日および完了予定日】を入力してください');
			}
			if (!this.formValues.matterFlg){
				this.errorList.push('【補助事業に関して生ずる収入金に関する事項】を選択してください');
			}
			if (this.formValues.matterFlg == '1' && this.formValues.matterContents == ''){
				this.errorList.push('【該当事項】を入力してください');
			}
			if (!this.formValues.representativeName){
				this.errorList.push('【代表者名】を入力してください');
			}
			if (!this.formValues.representativePosition){
				this.errorList.push('【役職】を入力してください');
			}
			if (!this.formValues.chairmanName){
				this.errorList.push('【会長名】を入力してください');
			}
			if (!this.formValues.address){
				this.errorList.push('【住所】を入力してください');
			}
			if (this.errorList.length >= 1) {
				return false;				
			} else {
				return true;
			}
		},
		async downloadYoshikiFile (id, fileName, $event) {
			let params = new FormData();
			params.append('action', 'download_yoshiki_file');
			params.append('params', JSON.stringify({
				'id': id,
				'offering_code': this.getUserStatus.offeringCode
			}));
			let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
			if (response.data.size >= 1) {
				this.downloadCsvBlob(response.data, fileName);
			}
		},
		dropFile (type, file) {
			this.fileList[type] = file[0];
		},
		selectFile (type, file) {
			this.fileList[type] = file[0];
		},	
		showAddJointCompanyDialog () {
			this.dialogVisible.addJointCompany = true;
			if (this.$refs.addJointCompany) {
				this.$refs.addJointCompany.getJointCompanyList();
			}
		},
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		}),
		disabledAddJointCompany () {
			return (this.uploadFileId ? false : true)
		},
		editPatternName () {
			return (this.isUpdate ? '修正' : '新規登録');
		},
	}

};
</script>

<style lang='scss' scoped>

	.input-form {
		&__row {
			@apply flex items-center mb-3;
			> span {
				@apply flex-none w-96;
			}
			> div {
				@apply flex-grow;
			}
		}
	}

	.upload-form {
		> div.upload-wrap {
			@apply flex items-center h-24 border p-3;
			> div:nth-child(1) {
				@apply flex-none w-40 mr-3 h-full flex items-center;
			}
			> div:nth-child(2) {
				@apply flex-none w-7/12 mr-3 h-full;
			}
			> div:nth-child(3) {
				@apply flex-grow;
			}
		}
	}

</style>
