<template>
	<div>
		<div class='page-header'>
			<div>審査員割り当て</div>
			<div>
				<el-button size='small' plain type='primary' @click.stop='handleAssignStatusList' :disabled='disabled'>割当状況確認</el-button>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>補助対象事業者別 審査員一覧</span>
			</div>
			<el-card class='w-full mb-10' shadow='never'> 
				<div>
					<div class='mb-5'>
						<el-pagination
							background
							@current-change='currentChange'
							:current-page.sync='currentPage'
							:page-size='listSize'
							layout='total, prev, pager, next'
							:total='allocationListCount' />
					</div>
					<div>
						<el-table
							:data="allocationList"
							style="width: 100%"
							row-key="id"
							lazy
						>
							<el-table-column
								align='center'
								type='index'
								width='50'
								label='No'
								:index='(currentPage-1)*10+1'
							/>
							<el-table-column align='center' label='受付番号' width='100'>
								<template slot-scope='scope'>
									{{ scope.row.apply_code }}
								</template>
							</el-table-column>					
							<el-table-column prop='prefecture' label='都道府県' width='150' />
							<el-table-column prop='shokokai' label='商工会' width='250' />
							<el-table-column prop='business_practitioner' label='補助対象者' />
							<el-table-column align='center' prop='judge_name1' label='審査員A' width='150' />
							<el-table-column align='center' prop='judge_name2' label='審査員B' width='150' />
							<el-table-column align='center' prop='judge_name3' label='審査員C' width='150' />
							<el-table-column align='center' fixed='right' label='割り当て' width='100'>
								<template slot-scope='scope'>
									<el-button
										type='primary'
										plain
										size='mini'
										@click="showAllocationJudgeDialog(scope.$index, scope.row)"
										icon='el-icon-user-solid'
										:disabled='disabled'
									/>
								</template>
							</el-table-column>					
						</el-table>
					</div>
				</div>		
			</el-card>
		</div>

		<!-- 審査員割り当てダイアログ Start -->
		<el-dialog title='審査員割り当て' :visible.sync='dialogVisible.allocationJudge'>
			<select-judge ref='selectJudge' v-model='param' @regist='registAllocationJudge' />
		</el-dialog>

		<el-dialog title='割当状況確認' :visible.sync='dialogVisible.assignStatusList' width='700px'>
			<assign-list ref='assignList' v-model='param' />
		</el-dialog>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import SelectJudge from '@/components/dialog/SelectJudge';
import AssignList from '@/components/dialog/AssignList';

const API_ENDPOINT = '/allocation-judge';

export default {
	name: 'AllocationJudge',
	data () {
    	return {
			currentPage: 0,
			listSize: 10,
			allocationListCount: 0,
			allocationList: [],
			dialogVisible: {
				allocationJudge: false,
				assignStatusList: false,
			},
			param: {
				uploadFileId: '',
				prefectureCode: '',
				offeringCode: ''
			},
			disabled: true,
    	};
	},
	components: {
		'select-judge': SelectJudge,
		'assign-list': AssignList,
	},
	created () {
	},
	mounted () {
		this.$nextTick(function () {
			this.$emit('selectPage', '1');
			this.disabled = this.getFaseStatus['2'];
			this.getAllocationJudgeList();
		});
	},
	methods: {
		async getAllocationJudgeList () {
			// ローディング開始
			this.startLoading('一覧を取得しています。しばらくおまちください。');
			try {
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'allocation_judge_list',
					params: JSON.stringify({
						prefecture_code: this.getUserStatus.prefectureCode,
						shokokai_code: this.getUserStatus.shokokaiCode,
						authority: this.getUserStatus.authority,
						offering_code: this.getUserStatus.offeringCode,
						offset: this.listSize * (this.currentPage - 1),
						limit: this.listSize
					})
				});
				this.allocationList = result.data.data;
				this.allocationListCount = result.data.count;
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		showAllocationJudgeDialog (index, row) {
			this.uploadFileId = row.apply_info_id;
			this.$set(this.param, 'uploadFileId', row.apply_info_id);
			this.$set(this.param, 'prefectureCode', row.prefecture_code);
			this.$set(this.param, 'offeringCode', this.getUserStatus.offeringCode);
			if (this.$refs.selectJudge) {
				this.$refs.selectJudge.doInit(this.uploadFileId);
			}
			this.dialogVisible.allocationJudge = true;
		},
		async registAllocationJudge (judgeList) {

			// ローディング開始
			this.startLoading('審査員を登録しています。しばらくおまちください。');

			try {
				let params = new FormData();
				params.append('action', 'allocation_judge');
				params.append('params', JSON.stringify({
					'applyInfoId': this.param.uploadFileId,
					'judge1': judgeList[0].kojin_no,
					'judge2': judgeList[1].kojin_no,
					'judge3': judgeList[2].kojin_no,
				}));
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, params);
				this.showMessage('JUDGE_ALLOCATION', result.data.result);
				if (result.data.result == 'OK') {
						// 正常終了の場合
						this.getAllocationJudgeList();
				} else {
						// 異常終了の場合
				}
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
				this.dialogVisible.allocationJudge = false;
			}	
		},
		currentChange () {
			this.getAllocationJudgeList();
		},
		async handleAssignStatusList () {
			this.$set(this.param, 'offeringCode', this.getUserStatus.offeringCode);
			if (this.$refs.assignList) {
				this.$refs.assignList.doInit();
			}
			this.dialogVisible.assignStatusList = true;
		},
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getFaseStatus: 'faseStatusManager/getFaseStatus',			
		}),
	}
};
</script>

<style lang='scss' scoped>

</style>
