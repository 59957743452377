export default {
    data () {
        return {
            GRAPH: {
                RADAR: '01',
                BAR: '02',
                LINE: '03',
                PIE: '04',
                DOUGHNUT: '05'
            }
        };
    },
    methods: {
        /**
         * getMonthStartYMD
         * 引数{ 対象年月日 }で指定された年月を基準として、月カレンダーの開始年月日を返す。
         * 引数がnullの場合は、システム日付けを基準年月日とする。
         * @param {date} date - 対象年月日
         * return {date} startDate - カレンダーの開始年月日
         */
        getMonthStartYMD (date) {
            // eslint-disable-next-line no-unneeded-ternary
            let toDate = (date ? date : new Date());
            let toMonthFirstDay = new Date(toDate.getFullYear(), toDate.getMonth(), 0);
            let beforeMonthDayCount = toMonthFirstDay.getDay();
            let startDate = new Date(toMonthFirstDay.setDate(toMonthFirstDay.getDate() - beforeMonthDayCount));
            return startDate;
        },

        formatDate (date, format) {
            let dt = new Date(date);
            format = format.replace(/YYYY/, dt.getFullYear());
            format = format.replace(/MM/, ('00' + (dt.getMonth() + 1)).slice(-2));
            format = format.replace(/DD/, ('00' + dt.getDate()).slice(-2));
            return format;
        },

        setDate (date, type, val) {
            let dt = new Date(date);
            switch (type) {
                case 'Y':
                    // return new Date(dt.setFullYear(dt.getFullYear() + val));
                    break;
                case 'M':
                    return new Date(dt.setMonth(dt.getMonth() + val));
                case 'D':
                    return new Date(dt.setDate(dt.getDate() + val));
                default:
                    break;
            }
        }
    },
    computed: {
    }
};
