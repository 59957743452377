import { render, staticRenderFns } from "./PlanChangeDetail.vue?vue&type=template&id=44f093bb&scoped=true&"
import script from "./PlanChangeDetail.vue?vue&type=script&lang=js&"
export * from "./PlanChangeDetail.vue?vue&type=script&lang=js&"
import style0 from "./PlanChangeDetail.vue?vue&type=style&index=0&id=44f093bb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f093bb",
  null
  
)

export default component.exports