<template>
	<div>
		<div class='page-header'>
			<div>計画変更申請：{{ editPatternName }}</div>
			<div>
			</div>
		</div>
		<div>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>申請情報</span>
				</div>
				<div class='input-form__row'>
					<span>変更の内容</span>
					<div>
						<el-input
							type="textarea"
							:rows="2"
							v-model="formValues.changes">
						</el-input>								
					</div>
				</div>
				<div class='input-form__row'>
					<span>補助事業の開始日および完了予定日</span>
					<div>
						<div>
							<span class='mr-3'>交付決定日</span>
							<span class='mr-3'>〜</span>
								<el-date-picker
								v-model='formValues.endDate'
								type="date"
								placeholder='選択してください'
								value-format='yyyy-MM-dd'
								size='small'>
							</el-date-picker>
						</div>
					</div>
				</div>
			</el-card>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>申請様式ファイル選択</span>
				</div>
				<div class='upload-form'>
					<div>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式第４</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai4' @drop='dropFile("yoshikiDai4", $event)' @select='selectFile("yoshikiDai4", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai4.id, uploadFileInfo.yoshikiDai4.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai4.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai4.lastUpdate }}</div>
						</div>
					</div>
					<div>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式第４別紙</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai4B' @drop='dropFile("yoshikiDai4B", $event)' @select='selectFile("yoshikiDai4B", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai4B.id, uploadFileInfo.yoshikiDai4B.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai4B.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai4B.lastUpdate }}</div>
						</div>
					</div>
				</div>
			</el-card>
			<el-button type='primary' class='mb-8 w-full' @click.stop='registApplyData'>登録する</el-button>			
		</div>

		<el-dialog title='入力エラー' :visible.sync='dialogVisible.inputError'>
			<div class='p-2' v-for='(item, index) in errorList' :key='index'>
				{{ item }}
			</div>
		</el-dialog>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import FileUploadForm from '@/components/custom/FileUpdateForm';

const API_ENDPOINT = '/plan-change-detail';

export default {
	name: 'PlanChangeDetail',
	props: {
		params: {},
		value: {}
	},
	data () {
    	return {
			formValues: {
				applyInfoId: '',
				changes: '',
				endDate: '',
			},			
			fileList: {
				'yoshikiDai4': '',
				'yoshikiDaiB4': '',
			},
			uploadFileInfo: {
				'yoshikiDai4': {id: '', fileName: '', lastUpdate: ''},
				'yoshikiDai4B': {id: '', fileName: '', lastUpdate: ''},
			},
			dialogVisible: {
				inputError: false,
				modification: false
			},
			errorList: [],
			newEdition: false
    	};
	},
	components: {
		'file-upload-form': FileUploadForm,
	},
	created () {
		this.newEdition = this.$route.params.data.newEdition;
		// ローディング開始
		this.startLoading('データを取得しています。しばらくおまちください。');
		try {
			if (this.$route.params.id) {
				this.applyInfoId = this.$route.params.id;
			}
			if (!this.$route.params.data.newEdition) {
				this.getApplyChangeData();
			}

		} catch (e) {
			this.$logger.error(e.message);				
		} finally {
			// ローディング停止
			this.stopLoading();
		}
	},
	mounted () {
	},
	methods: {
		async getApplyChangeData () {

			this.startLoading('データを取得しています。しばらくおまちください。');
			try {
				// ファイルアップロード情報の取得
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_apply_change_data',
					params: JSON.stringify({
						id: this.applyInfoId,
						edition: this.$route.params.data.edition
					})
				});
				if (result.data.result == 'OK') {
					console.dir(result.data.data);
					this.formValues.changes = '';
					this.formValues.endDate = '';
					Object.keys(this.uploadFileInfo).forEach((key) => {
						this.$set(this.uploadFileInfo, key, {id: '', fileName: '', lastUpdate: ''});
					});
					// データが存在する場合は画面に反映させる
					if (result.data.data.length >= 1) {
						if (this.applyInfoId) {
							this.formValues.changes = result.data.data[0].changes;
							this.formValues.endDate = result.data.data[0].end_date;
							result.data.data[0].yoshiki_info.forEach((value, index) => {
								switch (value.id_name) {
									case 'yoshikiDai4':
										this.uploadFileInfo.yoshikiDai4.id = value.id;
										this.uploadFileInfo.yoshikiDai4.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai4.lastUpdate = value.last_update;
										break;
									case 'yoshikiDai4B':
										this.uploadFileInfo.yoshikiDai4B.id = value.id;
										this.uploadFileInfo.yoshikiDai4B.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai4B.lastUpdate = value.last_update;
										break;
									default:
										break;
								}
							});
						}
					}
				}
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		async registApplyData () {
			if (!this.formCheck()) {
				this.dialogVisible.inputError = true;
				return false;
			}
			this.startLoading('ファイルをアップロードしています。しばらくおまちください。');
			try {
				let params = new FormData();
				params.append('action', 'regist_apply_change_data');
				params.append('params', JSON.stringify({
					'id': this.applyInfoId,
					'offeringCode': this.getUserStatus.offeringCode,
					'userId': this.getUserStatus.userId,
					'changes': this.formValues.changes,
					'endDate': this.formValues.endDate,
					'newEdition': this.newEdition
				}));
				Object.keys(this.fileList).forEach((key) => {
					if (this.fileList[key] != '') {
						console.dir(this.fileList[key]);
						params.append(key, this.fileList[key]);
					}
				});

				let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
				if (response.data.size >= 1) {
					this.downloadCsvBlob(response.data, '取込処理結果.xlsx');
					this.$message({
						'type': 'success', 
						'message': '申請情報の登録が完了しました。申請様式ファイルの登録結果は「登録処理結果.xlsx」をご確認ください。',
						'duration': 5000
					});
				} else {
					this.$message({
						'type': 'success', 
						'message': '申請情報の登録が完了しました。'
					});
				}

				// ファイルのアップロードが完了した場合は強制的に「修正」モードに変更する
				this.newEdition = false;

			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
				this.dialogVisible.modification = false;
			}
		},
		formCheck () {
			this.errorList = [];
			if (!this.formValues.changes){
				this.errorList.push('【変更の内容】を入力してください');
			}
			if (this.errorList.length >= 1) {
				return false;				
			} else {
				return true;
			}
		},
		async downloadYoshikiFile (id, fileName, $event) {
			let params = new FormData();
			params.append('action', 'download_yoshiki_file');
			params.append('params', JSON.stringify({
				'id': id,
				'offering_code': this.getUserStatus.offeringCode,
				'edition': this.$route.params.data.edition,				
			}));
			let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
			if (response.data.size >= 1) {
				this.downloadCsvBlob(response.data, fileName);
			}
		},
		dropFile (type, file) {
			this.fileList[type] = file[0];
		},
		selectFile (type, file) {
			this.fileList[type] = file[0];
		},		
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		}),
		editPatternName () {
			return (this.newEdition ? '版改定（第' + this.$route.params.data.edition + '版）' : '修正（第' + this.$route.params.data.edition + '版）');
		},
	}

};
</script>

<style lang='scss' scoped>

	.input-form {
		&__row {
			@apply flex items-center mb-3;
			> span {
				@apply flex-none w-96;
			}
			> div {
				@apply flex-grow;
			}
		}
	}

	.upload-form {
		> div {
			@apply flex items-center h-24 border p-3;
			> div:nth-child(1) {
				@apply flex-none w-40 mr-3 h-full flex items-center;
			}
			> div:nth-child(2) {
				@apply flex-none w-7/12 mr-3 h-full;
			}
			> div:nth-child(3) {
				@apply flex-grow;
			}
		}
	}

</style>
