const loggerPlugin = {
	install (Vue, { level }) {
		// optionsのチェック
		if (!level) {
			console.error('no settings logger level');
			return;
		}

		const LEVEL = {
			RUN: 0,
			ERROR: 1,
			WARN: 2,
			LOG: 3,
			INFO: 4,
			DEBUG: 5,
			FULL: 5
		}

		let lv = LEVEL[level];

		// グローバルメソッド
		const debug = (msg) => {
			(lv >= LEVEL.DEBUG) && console.debug('debug:', msg);
		};
		const info = (msg) => {
			(lv >= LEVEL.INFO) && console.info('info:', msg);
		};
		const log = (msg) => {
			(lv >= LEVEL.LOG) && console.log('log:', msg);
		};
		const warn = (msg) => {
			(lv >= LEVEL.WARN) && console.warn('warn:', msg);
		};
		const error = (msg) => {
			(lv >= LEVEL.ERROR) && console.error('error:', msg);
		};

		Vue.prototype.$logger = {
			'debug': debug,
			'info': info,
			'log': log,
			'warn': warn,
			'error': error
		};
	}
};

export default loggerPlugin;