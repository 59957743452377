<template>
	<div class='h-screen w-screen h-10'>
		<div class='w-screen h-screen flex justify-center items-center'>
			<div>
				
				<div class='mb-8 text-xl text-center'>
					ビジネスコミュニティ型補助金<br/>申請管理システム
				</div>
<!--
				<el-button class='w-full' type='primary' @click='dataUpload' :loading='loading' size='small'>data upload</el-button>
-->
				<div class='login-form'>
					<div class='login-form__row'>
						<div>公募：</div>
						<div>
							<el-select ref='offering' v-model='form.offeringCode' clearable placeholder='選択してください' size='small'>
								<el-option v-for='item in offeringList' :key='item.offering_code' :label='item.offering_name' :value='item.offering_code'/>
							</el-select>
						</div>
					</div>
					<div class='login-form__row'>
						<div>都道府県：</div>
						<div>
							<el-select ref='prefecture' v-model='form.prefectureCode' clearable placeholder='選択してください' size='small'>
								<el-option v-for='item in prefList' :key='item.value' :label='item.value + "：" + item.label' :value='item.value'/>
							</el-select>
						</div>
					</div>
					<div class='login-form__row'>
						<div>ユーザーID：</div>
						<div>
							<el-input ref='userId' v-model='form.userId' size='small' />
						</div>
					</div>
					<div class='login-form__row'>
						<div>パスワード：</div>
						<div>
							<el-input v-model='form.password' show-password size='small' />
						</div>
					</div>
				</div>
				<el-button class='w-full' type='primary' @click='login' :loading='loading' size='small'>ログイン</el-button>
				<div class='text-red-800 mt-3'>{{ errorMsg }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Login',
	data () {
		return {
			form: {
				prefectureCode: '',
				userId: '',
				password: '',
				offeringCode: ''
			},
			errorMsg: '',
			prefList: [],
			offeringList: [],
			loading: false
		};
	},
	created () {
	
		this.doInit();

		// 履歴情報を削除
		history.pushState(null, null, null);
		if(window.history && window.history.pushState){
			history.pushState('nohb', null, '');
			window.addEventListener('popstate', function(e){
				if(!e.state){
					history.pushState('nohb', null, '');
					return;
				}
			}, false);
		}
	},
	mounted () {
		// 初期カーソルを「都道府県」にセット
		this.$refs.prefecture.focus();
	},
	methods: {
		async doInit () {
			try {
				// 公募マスタの取得
				let offeringList = await this.$ajaxPlugin.post("/offering",{ action: 'get', params: JSON.stringify({})});
				this.offeringList = offeringList.data.data;
				this.form.offeringCode = this.offeringList[0].offering_code;

				// 都道府県リストの取得
				let prefList = await this.$ajaxPlugin.post('/common', { action: 'get', params: JSON.stringify({ common_name: 'prefecture'})});
				this.prefList = prefList.data.data;
			} catch (e) {
				this.$logger.error(e.message);				
			}
		},
		async login () {
			this.loading = true;
			let result = await this.$ajaxPlugin.post('/login', {
				action: 'login',
				params: JSON.stringify({
					prefectureCode: this.form.prefectureCode,
					userId: this.form.userId,
					password: this.form.password
				})
			});
			if (result.status === 200) {
				switch (result.data.result) {
					case 'OK':
						this.$logger.info('認証成功');
						// 職員関連情報のセット
						result.data['offeringCode'] = this.form.offeringCode;
						this.$store.dispatch('userManager/setUserStatus', result.data);
						// トップページに遷移
						this.$router.push({ name: 'portal', params: { auth: true } }).catch(err => {});
						break;
					case 'NODATA':
						this.$logger.error('認証失敗');
						this.errorMsg = 'ログインIDまたはパスワードが正しくありません。';
						break;
					case 'NG':
						this.$logger.error('認証失敗');
						this.errorMsg = 'ログインエラーが発生しました。システム管理者にお問い合わせください。';
						break;
				}
			} else {
				this.errorMsg = 'ログインエラーが発生しました。システム管理者にお問い合わせください。';
			}
			this.loading = false;
		},
		async dataUpload () {

			let response = await this.$ajaxPlugin.post('/apply-upload-all', {
				action: 'post',
				params: JSON.stringify({})
			}, { responseType: "blob" });
			this.downloadCsvBlob(response.data, 'report.xlsx');

		}
	}
};
</script>

<style lang='scss' scoped>

.system-title {
	color: var(--login-title-color);
	text-align: center;
}
.error-msg{
	color: rgb(150, 54, 52);
	font-weight: bold;
	width: 100%;
	height: 50px;
	display: block;
	margin-top: 10px;
}

.login-form {
	&__row {
		@apply flex items-center mb-5;
		div:nth-child(1) {
			@apply flex-grow flex justify-end mr-2;
		}
		div:nth-child(2) {
			@apply flex-none w-80;
		}
	}
}	

</style>