<template>
    <div>
        <el-table
            :data="assignList"
            style="width: 100%"
            row-key="id"
            height='500px'
            lazy
        >
            <el-table-column
                align='center'
                type='index'
                width='50'
            />
            <el-table-column prop='kojin_no' label='個人No' width='100' />
            <el-table-column prop='name' label='氏名' />
            <el-table-column label='都道府県'>
                <el-table-column prop='prefecture_code' label='コード' width='80' />
                <el-table-column prop='prefecture_name' label='名称' width='120' />
            </el-table-column>
            <el-table-column prop='allocation_count' label='割当件数' width='80' align='right' />
        </el-table>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT = '/allocation-judge';

export default {
    name: 'AssignList',
	props: [
    	'value'
    ],
    data () {
        return {
                assignList: [],
            };
        },
        mounted () {
            this.getAssignList();
        },
        created () {
        },
        methods: {
        doInit () {
            this.getAssignList();
        },
        async getAssignList () {
            console.log(this.value);
            let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
                action: 'get_assign_list',
                params: JSON.stringify({ offeringCode: this.value.offeringCode })
            });
            this.assignList = result.data.data;
        },
    },
    computed: {
        ...mapGetters({
            getUserStatus: 'userManager/getUserStatus',
            getCommonData: 'commonListManager/getCommonData',
        })
    }
};

</script>

<style lang='scss' scoped>

</style>