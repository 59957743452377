const API_ENDPOINT = 'offering';

export default {
    data () {
        return {
            objLoad: null
        };
    },
	created () {
	},
    methods: {
		startLoading (text=null) {
			if (!text) {
				text = 'データをロード中です'
			}
            this.objLoad = this.$loading({
                lock: true,
                text: text,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)',
                customClass: 'loading'
            });
		},
		stopLoading () {
            if (this.objLoad) {
                this.objLoad.close();
            }
        },
        
		downloadCsvBlob(blob, fileName) {
			if (window.navigator.msSaveOrOpenBlob) {
				// for IE,Edge
				window.navigator.msSaveOrOpenBlob(blob, fileName);
			} else {
				// for chrome, firefox
				const url = URL.createObjectURL(new Blob([blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
				const linkEl = document.createElement('a');
				linkEl.href = url;
				linkEl.setAttribute('download', fileName);
				document.body.appendChild(linkEl);
				linkEl.click();

				URL.revokeObjectURL(url);
				linkEl.parentNode.removeChild(linkEl);
			}
		},
    },
    computed: {
    }
};
