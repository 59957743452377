<template>
	<div class='h-full'>
		<div class='page-header'>
			<div>データ出力</div>
			<div>
				<el-button size='small' icon='el-icon-download' plain type='primary' @click.stop='outputReport'>帳票出力</el-button>
			</div>
		</div>
		<div>
			<div class='section-header'>
				<span>申請</span>
			</div>
			<el-card class='section-report' shadow='never'>
				<el-radio v-model="outputType" label="00">申請データ集計表</el-radio>
			</el-card>
			<div class='section-header'>
				<span>審査</span>
			</div>
			<el-card class='section-report' shadow='never'>
				<el-radio v-model="outputType" label="10">審査員割当状況確認表</el-radio>
				<el-radio v-model="outputType" label="11">審査員割当表</el-radio>
				<el-radio v-model="outputType" label="12">採点評価表</el-radio>
				<el-radio v-model="outputType" label="13">全審査員採点集計表</el-radio>
			</el-card>
			<div class='section-header'>
				<span>計画変更</span>
			</div>
			<el-card class='section-report' shadow='never'> 
			</el-card>
			<div class='section-header'>
				<span>実績報告</span>
			</div>
			<el-card class='section-report' shadow='never'> 
				<el-radio v-model="outputType" label="30">チェックツール用CSV</el-radio>
			</el-card>

		</div>
	</div>

</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT = '/output-report';

export default {
	name: '',
	data () {
    	return {
			outputType: ''
    	};
	  },
	components: {
	},
	created () {
	},
	mounted () {
	},
	methods: {
        async outputReport () {

			let reportName = '';
			let action = '';
			let fileName = '';

			if (!this.outputType) {
				this.$message({
					message: '出力対象が選択されていません。',
					type: 'error'
				});
				return false;
			}

			switch(this.outputType) {
				case '00':
					reportName = '申請データ集計表';
					action = 'apply_list';
					fileName = '申請データ集計表.xlsx';
					break;
				case '10':
					reportName = '審査員割当状況確認表';
					action = 'allocation_status_list';
					fileName = '審査員割当状況確認表.xlsx';
					break;
				case '11':
					reportName = '審査員割当表';
					action = 'allocation_judge_list';
					fileName = '審査員割当表.xlsx';
					break;
				case '12':
					reportName = '採点評価表';
					action = 'scoring_evaluation_list';
					fileName = '採点評価表.zip';
					break;
				case '13':
					reportName = '全審査員採点集計表';
					action = 'scoring_result_all_list';
					fileName = '全審査員採点集計表.xlsx';
					break;
				case '30':
					reportName = '実績報告用CSV';
					action = 'achievement_csv';
					fileName = 'achievement_check.csv';
					break;
				default:
					break;					
			}

			this.startLoading(reportName + 'を出力しています。しばらくおまちください。');
			try {
				let params = new FormData();
				params.append('action', action);
				params.append('params', JSON.stringify({
					offering_code: this.getUserStatus.offeringCode,
				}));
				let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
				this.downloadCsvBlob(response.data, fileName);
			} catch (e) {
				this.$logger.error(e.message);
				this.showMessage('ERROR', 'WEB_API_ERROR');
			} finally {
				// ローディング停止
				this.stopLoading();
			}
        },
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		}),
	}

};
</script>

<style lang='scss' scoped>

</style>
