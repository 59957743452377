
const state = {
    userStatus: {}
};


// stateの更新
const mutations = {
    setUserStatus (state, userStatus) {
        state.userStatus = userStatus;
    }
};

// mutationsをコミット
// 非同期処理を行う場合はactionのメソッド内で実行しmutationsをコミットする
const actions = {
    setUserStatus (context, content) {
        context.commit('setUserStatus', content);
    }
};

const getters = {
    'getUserStatus': state => state.userStatus
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
