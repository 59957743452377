import Vue from 'vue';
import VueRouter from 'vue-router';
import Root from '@/components/Root';
import Login from '@/components/pages/Login';
import Main from '@/components/pages/Main';
// ポータル
import PortalGroup from '@/components/pages/portal/PortalGroup';
import Portal from '@/components/pages/portal/Portal';

// 申請
import ApplyGroup from '@/components/pages/apply/ApplyGroup';
import ApplyForm from '@/components/pages/apply/ApplyForm';
import ApplyFormDetail from '@/components/pages/apply/ApplyFormDetail';

// 審査
import JudgeGroup from '@/components/pages/judge/JudgeGroup';
import AllocationJudge from '@/components/pages/judge/AllocationJudge';
import ScoringResultImport from '@/components/pages/judge/ScoringResultImport';
import AdjustmentEvaluationImport from '@/components/pages/judge/AdjustmentEvaluationImport';
import AdoptionResult from '@/components/pages/judge/AdoptionResult';

// 計画変更
import ChangeGroup from '@/components/pages/change/ChangeGroup';
import PlanChange from '@/components/pages/change/PlanChange';
import PlanChangeDetail from '@/components/pages/change/PlanChangeDetail';
import Cancel from '@/components/pages/change/Cancel';
import CancelDetail from '@/components/pages/change/CancelDetail';

// 実績報告
import AchievementGroup from '@/components/pages/achievement/AchievementGroup';
import Achievement from '@/components/pages/achievement/Achievement';
import AchievementDetail from '@/components/pages/achievement/AchievementDetail';
import Invoice from '@/components/pages/achievement/Invoice';
import InvoiceDetail from '@/components/pages/achievement/InvoiceDetail';

// 状況報告
import BusinessResultGroup from '@/components/pages/businessresult/BusinessResultGroup';
import BusinessResult from '@/components/pages/businessresult/BusinessResult';
import BusinessResultDetail from '@/components/pages/businessresult/BusinessResultDetail';

// 帳票出力
import ReportGroup from '@/components/pages/report/ReportGroup';
import OutputReport from '@/components/pages/report/OutputReport';

/*
import Application from '@/components/pages/Application';
import Upload from '@/components/pages/Upload';
import UploadList from '@/components/pages/UploadList';
import JudgeAllocation from '@/components/pages/JudgeAllocation';
*/
Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: Root,
		redirect: '/main',
		children: [
			{
				path: '/login',
				component: Login,
				props: false,
			},
			{
				path: '/main',
				name: 'main',
				component: Main,
				meta: {
					requireAuth: true
				},
				children: [
					{
						path: '/portal-group',
						name: 'portalGroup',
						component: PortalGroup,
						props: true,
						children: [
							{
								path: '/portal',
								name: 'portal',
								component: Portal,
								props: true,
							},
						],
					},
					{
						path: '/apply-group',
						name: 'applyGroup',
						component: ApplyGroup,
						props: true,
						children: [
							{
								path: '/apply-form',
								name: 'applyForm',
								component: ApplyForm,
								props: true,
							},
							{
								path: '/apply-form-detail',
								name: 'applyFormDetail',
								component: ApplyFormDetail,
								props: true,
							},
						],
					},
					{
						path: '/judge-group',
						name: 'judgeGroup',
						component: JudgeGroup,
						props: true,
						children: [
							{
								path: '/allocation-judge',
								name: 'allocationJudge',
								component: AllocationJudge,
								props: true,
							},
							{
								path: '/scoring-result-import',
								name: 'scoringResultImport',
								component: ScoringResultImport,
								props: true,
							},
							{
								path: '/adjustment-evaluation-import',
								name: 'adjustmentEvaluationImport',
								component: AdjustmentEvaluationImport,
								props: true,
							},
							{
								path: '/adoption-result',
								name: 'adoptionResult',
								component: AdoptionResult,
								props: true,
							},
						],
					},
					{
						path: '/change-group',
						name: 'changeGroup',
						component: ChangeGroup,
						props: true,
						children: [
							{
								path: '/plan-change',
								name: 'planChange',
								component: PlanChange,
								props: true,
							},
							{
								path: '/plan-change-detail',
								name: 'planChangeDetail',
								component: PlanChangeDetail,
								props: true,
							},
							{
								path: '/cancel',
								name: 'cancel',
								component: Cancel,
								props: true,
							},
							{
								path: '/cancel-detail',
								name: 'cancelDetail',
								component: CancelDetail,
								props: true,
							},
						],
					},
					{
						path: '/achievement-group',
						name: 'achievementGroup',
						component: AchievementGroup,
						props: true,
						children: [
							{
								path: '/achievement',
								name: 'achievement',
								component: Achievement,
								props: true,
							},
							{
								path: '/achievement-detail',
								name: 'achievementDetail',
								component: AchievementDetail,
								props: true,
							},
							{
								path: '/invoice',
								name: 'invoice',
								component: Invoice,
								props: true,
							},
							{
								path: '/invoice-detail',
								name: 'invoiceDetail',
								component: InvoiceDetail,
								props: true,
							},
						],
					},
					{
						path: '/business-result-group',
						name: 'BusinessResultGroup',
						component: BusinessResultGroup,
						props: true,
						children: [
							{
								path: '/business-result',
								name: 'businessResult',
								component: BusinessResult,
								props: true,
							},
							{
								path: '/business-result-detail',
								name: 'businessResultDetail',
								component: BusinessResultDetail,
								props: true,
							},
						],
					},
					{
						path: '/report-group',
						name: 'reportGroup',
						component: ReportGroup,
						props: true,
						children: [
							{
								path: '/outputReport',
								name: 'outputReport',
								component: OutputReport,
								props: true,
							},
						],
					},

				]
			}
		]
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requireAuth)) {
		if (to.params.auth) {
			next();
		} else {
			next({
				path: 'login'
			});
//			next();			
		}
	} else {
		next();
	}
});

router.afterEach((to, from) => {
	/*
	if(to.meta && to.meta.title){
		let pageStack = Store.getters['breadCrumbsManager/getBreadCrumbs'];
		console.log(to.meta.path);
		let index = -1;
		// 既にスタックにページが存在するかチェック
		if (pageStack.length >= 1){
			pageStack.forEach((value, i) => {
				if (value.title == to.meta.title) {
					index = i;
					return false;
				}
			});	
		}
		// ページを戻ろうとしている場合はスタックから現在のページデータを削除する
		if (index >= 0) {
			console.log(pageStack.slice(0, index));
			console.log('pop');
			Store.dispatch('breadCrumbsManager/pop', pageStack.slice(0, index + 1));
		} else {
			console.log('push');
			Store.dispatch('breadCrumbsManager/push', { 'path': to.path, 'title': to.meta.title });
		}

		console.log(Store.getters['breadCrumbsManager/getBreadCrumbs']);
	}
	*/
})

export default router;
