const state = {
    faseStatus: {}
};


// stateの更新
const mutations = {
    setFaseStatus (state, faseStatus) {
        state.faseStatus = faseStatus;
    }
};

// mutationsをコミット
// 非同期処理を行う場合はactionのメソッド内で実行しmutationsをコミットする
const actions = {
    setFaseStatus (context, content) {
        context.commit('setFaseStatus', content);
    }
};

const getters = {
    'getFaseStatus': state => state.faseStatus
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
