<template>
    <div>
        <div class='input-form'>
            <div v-if='mode=="create"' class='input-form__row'>
                <span>申請方式：</span>
                <div>
                    <div>
                        <el-radio
                            v-for='(item, index) in getCommonData.applyType'
                            v-model='form.applyType' 
                            :label='item.value'
                            :key='index'
                        >
                            {{ item.label }}
                        </el-radio>
                    </div>
                </div>
            </div>
            <div class='input-form__row'>
                <span>都道府県：</span>
                <div>
                    <div v-if='this.getUserStatus.authority=="admin" || mode=="addJointCompany"'>
                        <el-select class='prefecture' ref='prefecture' v-model='form.prefectureCode' @change='getShokokaiList' clearable placeholder='都道府県' size='small'>
                            <el-option v-for='item in getCommonData.prefecture' :key='item.value' :label='item.value + "：" + item.label' :value='item.value'/>
                        </el-select>
                    </div>
                    <div v-else>
                        {{ this.getUserStatus.prefectureName }}
                    </div>
                </div>
            </div>
            <div class='input-form__row'>
                <span>商工会：</span>
                <div>
                    <div v-if='this.getUserStatus.authority==="admin" || this.getUserStatus.shokokaiCode==="0021"'>
                        <el-select class='shokokai' ref='shokokai' v-model='form.shokokaiCode' clearable placeholder='商工会' size='small'>
                            <el-option v-for='item in shokokaiList' :key='item.shokokai_code' :label='item.shokokai_code + "：" + item.shokokai_name' :value='item.shokokai_code'/>
                        </el-select>
                    </div>
                    <div v-else>
                        {{ this.getUserStatus.shokokaiName }}
                    </div>
                </div>
            </div>
            <div class='input-form__row'>
                <span>事業実施者（法人の内部組織）：</span>
                <div>
                    <div>
                        <el-radio
                            v-for='(item, index) in getCommonData.targetKbn'
                            v-model='form.targetKbn' 
                            :label='item.value'
                            :key='index'
                        >
                            {{ item.label }}
                        </el-radio>
                    </div>
                </div>
            </div>
        </div>
        <span slot='footer'>
            <div v-if='mode=="create"'>           
                <el-button class='block w-full' type='primary' @click='handleClick'>詳細画面へ</el-button>
            </div>
            <div v-if='mode=="addJointCompany"'>           
                <el-button class='block w-full' type='primary' @click='handleClick'>追加</el-button>
            </div>
        </span>

		<el-dialog title='入力エラー' :visible.sync='dialogVisible.showError' append-to-body>
			<div class='p-2' v-for='(item, index) in errorList' :key='index'>
				{{ item }}
			</div>
		</el-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT = '/shokokai-match';

export default {
	name: 'SelectOrganization',
	props: [
        'value',
        'mode'
    ],
	data () {
		return {
            shokokaiList: [],
			form: {
                applyType: '',
				prefectureCode: '',
				shokokaiCode: '',
				targetKbn: '',
            },
            dialogVisible: {
                showError: false
            },
            errorList: [],
		};
	},
	mounted () {
        // 連合会ユーザーの場合は、都道府県コードを初期セットする
        if (this.getUserStatus.shokokaiCode === '0021') {
            this.form.prefectureCode = this.getUserStatus.prefectureCode;
            this.getShokokaiList(this.getUserStatus.prefectureCode);
        }
	},
	created () {
	},
	methods: {
		async getShokokaiList (value) {
			let shokokaiList = await this.$ajaxPlugin.post(API_ENDPOINT, { mode: 'get', params: JSON.stringify({ prefecture_code: value }) });
			this.shokokaiList = shokokaiList.data.data;
        },
        handleClick () {
            let result = this.formCheck();
            if (result) {

                let prefecture = this.getCommonData.prefecture.filter((item) => {
                    return item.value === this.form.prefectureCode
                });
                let shokokai = this.shokokaiList.filter((item) => {
                    return item.shokokai_code === this.form.shokokaiCode
                });
                let targetKbn = this.getCommonData.targetKbn.filter((item) => {
                    return item.value === this.form.targetKbn
                });
                let userInfo = {
                    prefecture_name: prefecture[0].label,
                    shokokai_name: shokokai[0].shokokai_name,
                    target_kbn_name: '(' + targetKbn[0].label + ')',
                }
			    this.$store.dispatch('uploadInfoManager/setUploadInfo', userInfo);

                this.$emit('click', this.form);
            } else {
                this.dialogVisible.showError = true;
            }
        },
		formCheck () {
            this.errorList = [];
            switch (this.mode) {
                case 'create':
                    if (!this.form.applyType){
                        this.errorList.push('【申請方式】を選択してください');
                    }
                    if (!this.form.prefectureCode){
                        this.errorList.push('【都道府県】を入力してください');
                    }
                    if (!this.form.shokokaiCode){
                        this.errorList.push('【商工会】を選択してください');
                    }
                    if (!this.form.targetKbn){
                        this.errorList.push('【事業実施者（法人の内部組織】を選択してください');
                    }
                    break;
                case 'addJointCompany':
                    if (!this.form.prefectureCode){
                        this.errorList.push('【都道府県】を入力してください');
                    }
                    if (!this.form.shokokaiCode){
                        this.errorList.push('【商工会】を選択してください');
                    }
                    if (!this.form.targetKbn){
                        this.errorList.push('【事業実施者（法人の内部組織】を選択してください');
                    }
                    break;
                default:
                    break;
            }

			if (this.errorList.length >= 1) {
				return false;				
			} else {
				return true;
			}
		},        
    },
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getCommonData: 'commonListManager/getCommonData',
		})
	}
};

</script>

<style lang='scss' scoped>

	.input-form {
		&__row {
			@apply flex items-center mb-3;
			> span {
				@apply flex-none w-72;
			}
			> div {
				@apply flex-grow;
			}
		}
	}

</style>
