import Vue from 'vue';
import Vuex from 'vuex';

// 状態遷移管理用
import userManager from '@/store/modules/userManager.js';
import commonListManager from '@/store/modules/commonListManager.js';
import uploadInfoManager from '@/store/modules/uploadInfoManager.js';
import faseStatusManager from '@/store/modules/faseStatusManager.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        userManager: userManager,
        commonListManager: commonListManager,
        uploadInfoManager: uploadInfoManager,
        faseStatusManager: faseStatusManager
    }
});
