/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
import App from "@/components/App.vue";
import router from "@/router.js";
import store from '@/store/index';

// ElementUI
import ElementUI from 'element-ui';
//import 'element-ui/lib/theme-chalk/index.css';
import '@/../css/element-variables.scss';
import locale from 'element-ui/lib/locale/lang/ja';
Vue.use(ElementUI, {locale});

// Plugins
import ajaxPlugin from "@/plugins/ajaxPlugin";
import loggerPlugin from "@/plugins/loggerPlugin";
Vue.use(loggerPlugin, { level: 'debug' });
Vue.use(ajaxPlugin, { urlBase: location.protocol + "//" + location.host + "/api", axios: axios });

// Mixin
import utilMixin from '@/mixin/utilMixin';
import dateMixin from '@/mixin/dateMixin';
import messageMixin from '@/mixin/messageMixin';
Vue.mixin(utilMixin);
Vue.mixin(dateMixin);
Vue.mixin(messageMixin);

const app = new Vue({
    router: router,
    store,    
    el: '#app',
    render: h => h(App),
});
