
const state = {
    uploadInfo: {}
};


// stateの更新
const mutations = {
    setUploadInfo (state, uploadInfo) {
        state.uploadInfo = uploadInfo;
    }
};

// mutationsをコミット
// 非同期処理を行う場合はactionのメソッド内で実行しmutationsをコミットする
const actions = {
    setUploadInfo (context, content) {
        context.commit('setUploadInfo', content);
    }
};

const getters = {
    'getUploadInfo': state => state.uploadInfo
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
