<template>
    <div id="app" class='select-none'>
        <router-view/>
    </div>
</template>

<script>
import Vue from "vue";
import router from "vue-router";

export default {

    name: 'App',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },    
    mounted() {
    },
    computed: {
    }
}

</script>
<style scoped>
    #app{
        width: 100%;
        height: 100%;
    }
    
</style>
