<template>
    <div> 
            <div class='button-wrap middle-right'>
                <el-button @click='dialogVisible.selectOrganization=true' type='primary'>共同実施者追加</el-button>
            </div>
        <div>
            <el-table
                :data="jointCompanyList"
                style="width: 100%"
                row-key="id"
                lazy
                height='400'
                 :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
                <el-table-column
                    align='center'
                    type='index'
                    width='50'
                />
                <el-table-column
                    prop='prefecture'
                    label='都道府県'
                    width='120'
                />
                <el-table-column
                    prop='shokokai'
                    label='商工会'
                    width='250'
                />
                <el-table-column
                    prop='target_name'
                    label='事業実施者'
                />
                <el-table-column
                    align='center'
                    fixed='right'
                    label='削除'
                    width='100'
                >
                    <template slot-scope='scope'>
                        <el-button
                            type='primary'
                            plain
                            size='mini'
                            icon='el-icon-delete'
                            @click="handleDelete(scope.$index, scope.row)" />
                    </template>
                </el-table-column>            
            </el-table>            
        </div>

		<!-- 共同実施者追加ダイアログ Start -->
		<el-dialog title='共同実施者追加' :visible.sync='dialogVisible.selectOrganization' append-to-body>
			<select-organization :mode='"addJointCompany"' @click='handleClick' />
		</el-dialog>
		<!-- 共同実施者追加ダイアログ End -->

    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import SelectOrganization from '@/components/dialog/SelectOrganization';

const API_ENDPOINT = '/apply-form-detail';

export default {
	name: 'AddJointCompany',
	props: [
        'value'
    ],
	data () {
		return {
            jointCompanyList: [],
            dialogVisible: {
                selectOrganization: false
            }
		};
	},
	mounted () {
        this.getJointCompanyList();
	},
	created () {
    },
	components: {
		'select-organization': SelectOrganization
	},    
	methods: {
        async getJointCompanyList () {
			let data = await this.$ajaxPlugin.post(API_ENDPOINT, {
				action: 'get_joint_company_list',
				params: JSON.stringify({
                    offeringCode: this.getUserStatus.offering_code,
                    uploadFileId: this.value,
                })
            });
            if (data.data.result === 'OK') {
                this.jointCompanyList = data.data.data;
            }
        },
        async handleClick (values) {
			let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
				action: 'add_joint_company',
				params: JSON.stringify({
                    offeringCode: this.getUserStatus.offeringCode,
                    uploadFileId: this.value,
					prefectureCode: values.prefectureCode,
					shokokaiCode: values.shokokaiCode,
					targetKbn: values.targetKbn,
				})
            });
            this.showMessage('ADD_JOINT_COMPANY', result.data.result);
            if (result.data.result === 'OK') {
                this.jointCompanyList = result.data.data;
                this.dialogVisible.selectOrganization = false;
                this.getJointCompanyList();
            }
        },
        async handleDelete (index, row) {
			let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
				action: 'delete_joint_company',
				params: JSON.stringify({
                    offeringCode: this.getUserStatus.offeringCode,
                    uploadFileId: this.value,
					prefectureCode: row.prefecture_code,
					shokokaiCode: row.shokokai_code,
					targetKbn: row.target_kbn
				})
            });
            this.showMessage('DELETE_JOINT_COMPANY', result.data.result);
            if (result.data.result === 'OK') {
                this.getJointCompanyList();
            }
        }
    },
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		}),
	}
};

</script>

<style lang='scss' scoped>

    .button-wrap {
        margin-bottom: 20px;
        text-align: right;
    }

</style>
