<template>
	<div class='group-wrap'>
		<div class='z-10'>
			<el-menu :default-active='index' mode='horizontal' @select='selectPage'>
				<el-menu-item index='1'>申請様式登録</el-menu-item>
			</el-menu>
		</div>
		<div>
			<div>
	        	<router-view @selectPage='selectPage' />
			</div>
		</div>
	</div>	
</template>

<script>

import { mapGetters } from 'vuex';

export default {
	name: 'ApplyGroup',
	data () {
    	return {
			index: '1'
    	};
	  },
	components: {
	},
	created () {
	},
	mounted () {
		this.doInit();
	},
	methods: {
		async doInit () {
			this.selectPage('1', '');
		},
		selectPage (key) {
			this.index = key;
			switch (key) {
				case '1':
					this.$router.push({ name: 'applyForm', params: { auth: true } }).catch(err => {});
					break;
				default:
					break;
			}
		}
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		}),
	}
};
</script>

<style lang='scss' scoped>

</style>
