<template>
    <div class='h-full w-full'>
        <div
            class='drop_area'
            @click='fileSelect'
            :class='{enter: isEnter}'
            @dragenter='dragEnter'
            @dragleave='dragLeave'
            @dragover.prevent
            @drop.prevent='dropFile'
        >
            <div class='flex items-center justify-center h-full'>{{ fileName }}</div>
        </div>
        <input 
            class='file-select justify-center' 
            ref='fileSelect' 
            type='file'
            :key='key'
            :multiple='multiple'
            @change='selectedFile' />
    </div>
</template>

<script>

export default {
	name: 'fileUpdateForm',
	props: {
        value: '',
        multiple: ''
    },
	data () {
		return {
            isEnter: false,
            fileName: 'ここにファイルをドラッグまたはクリックしてください。',
            key: 0,
		};
    },    
    created () {
    },
	methods: {
		dragEnter () {
			this.isEnter = true;
		},
		dragLeave () {
			this.isEnter = false;
		},
		dragOver () {

		},
		dropFile (event) {
            console.dir('dropFile');
            console.dir(event.dataTransfer.files);

            this.isEnter = false;
            if (!this.multiple) {
                this.fileName = event.dataTransfer.files[0].name;
            }
            //this.$emit('input', this.value);
            this.$emit('drop', event.dataTransfer.files);
        },
        selectedFile () {
            console.dir('selectedFile');
            if (!this.multiple) {
                this.fileName = this.$refs.fileSelect.files[0].name;
            }
            //this.$emit('input', this.$refs.fileSelect.files[0].name);
            this.$emit('select', this.$refs.fileSelect.files);
        },
        fileSelect () {
            this.$refs.fileSelect.click();
        },
        getFile () {
            return this.$refs.fileSelect.files[0];
        },
        clearFile () {
            this.$refs.fileSelect.value = null;
            this.fileName = 'ここにファイルをドラッグまたはクリックしてください。';
			this.$logger.info('ファイルのクリア');
        }
	}
};

</script>

<style lang='scss' scoped>

    .page-wrap {
        width: 100%;
    }
	.drop_area {
		height: 100%;
		width: 100%;
		border-style: dashed;
		border-width: 1px;
        border-color: #ccc;
        cursor: pointer;
        &:focus {
	    	background-color: rgb(191, 244, 248);
    	}

    }
	.drop_area.enter {
		background-color: rgb(191, 244, 248);
    } 
    .file-select {
        display: none;
    }

</style>