const ajaxPlugin = {
	install (Vue, { urlBase, axios, csrfToken }) {
		// optionsのチェック
		if (!axios) {
			console.error('no setting axios');
			return;
		}

		Vue.axios = axios;

		let token = document.head.querySelector('meta[name="csrf-token"]');

		axios.defaults.headers.common = {
			'X-Requested-With': 'XMLHttpRequest',
			'X-CSRF-TOKEN' : token.content
		};

		// グローバルメソッド
		const ajaxGet = async function (name, params, thenFnc, catchFnc, finallyFnc = null) {
			await axios.get(urlBase + name, {
				params: params
			}).then(result => {
				Vue.$logger.info(result);
				thenFnc(result);
			}).catch(error => {
				Vue.$logger.info(error);
				console.error(error);
				catchFnc(error);
			}).finally(() => {
				if (finallyFnc) {
					finallyFnc();
				}
			});
		};

		const ajaxPost = function (name, params, headers=null, fFnc=null) {
			return axios.post(urlBase + name, params, headers);
		}

		const ajaxDelete = async function (name, pk, thenFnc, catchFnc, finallyFnc = null) {
			await axios.delete(urlBase + name + '/' + pk + '/', {
			}).then(result => {
				Vue.$logger.debug(result);
				thenFnc(result);
			}).catch(error => {
				Vue.$logger.debug(error);
				catchFnc(error);
			}).finally(() => {
				if (finallyFnc) {
					finallyFnc();
				}
			});
		};

		Vue.prototype.$ajaxPlugin = {
			'get': ajaxGet,
			'post': ajaxPost,
			'delete': ajaxDelete
		};
	}
};

export default ajaxPlugin;