<template>
	<div>
		<header class='bg-main h-12 w-full flex items-center absolute'>
			<div class='flex-grow justify-start pl-5 text-white text-base'>
				ビジネスコミュニティ型補助金 申請管理システム
			</div>
			<div class='flex-none w-32 justify-end text-white'>
				{{ this.getUserStatus.userNm }}
			</div>
			<div class='flex-none w-16 justify-end text-center text-white text-xl p-2 hover:opacity-50 rounded cursor-pointer mr-5' @click='dialogVisible.logout=true'>
				<i class='el-icon-switch-button button' />
			</div>
		</header>
		<el-container class='h-screen pt-12'>
			<div class='absolute h-full'>
				<el-menu default-active="1" class="el-menu-vertical w-60 h-full" @open='navOpen' @close='navClose' :collapse='isCollapse' @select="selectPage">
					<div class='h-3 flex justify-end p-5'>
<!--
						<i class='el-icon-menu text-white' @click.stop='isCollapse=!isCollapse' />
-->
					</div>
					<el-menu-item index='1'>
						<i class='el-icon-s-home'></i>
						<span slot='title'>ポータル</span>
					</el-menu-item>
					<el-menu-item index='2'>
						<i class='el-icon-edit-outline'></i>
						<span slot='title'>申請</span>
					</el-menu-item>
					<el-menu-item index='3'>
						<i class='el-icon-s-custom'></i>
						<span slot='title'>審査</span>
					</el-menu-item>
					<el-menu-item index='4'>
						<i class="el-icon-refresh-right"></i>
						<span slot="title">計画変更</span>
					</el-menu-item>
					<el-menu-item index='5'>
						<i class='el-icon-chat-line-square'></i>
						<span slot='title'>実績報告</span>
					</el-menu-item>
					<el-menu-item index='6'>
						<i class='el-icon-document-add'></i>
						<span slot='title'>状況報告</span>
					</el-menu-item>
					<el-menu-item index='8'>
						<i class='el-icon-document'></i>
						<span slot='title'>データ出力</span>
					</el-menu-item>
					<el-menu-item index='9'>
						<i class='el-icon-setting'></i>
						<span slot='title'>システム管理</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class='pl-60 w-full h-full'>
				<transition>
	        		<router-view />
				</transition>
			</div>
		</el-container>

		<!-- ログアウトダイアログ Start -->
		<el-dialog title='ログアウト' :visible.sync='dialogVisible.logout' width='300px' >
			ログアウトします。よろしいですか？
			<div slot='footer'>
				<el-button type='primary' @click='logout'>はい</el-button>
				<el-button @click='dialogVisible.logout=false'>いいえ</el-button>
			</div>
		</el-dialog>
		<!-- ログアウトダイアログ End -->

	</div>

</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT_COMMON = '/common';
const API_ENDPOINT_OFFERING = '/offering';

export default {
	name: 'Main',
	data () {
    	return {
    		isCollapse: false,
			dialogVisible: {
				logout: false
			}
    	};
	  },
	components: {
	},
	created () {
	},
	mounted () {
		this.doInit();
	},
	methods: {
    	navOpen(key, keyPath) {
      	},
		navClose(key, keyPath) {
    	},
		logout () {
			this.dialogVisible.logout = false;
			this.$router.push({ path: '/login' });
		},
		selectPage (key, keyPath) {
			switch (key) {
				case '1':
					this.$router.push({ name: 'portal', params: { auth: true } }).catch(err => {});
					break;
				case '2':
					this.$router.push({ name: 'applyForm', params: { auth: true } }).catch(err => {});
					break;
				case '3':
					this.$router.push({ name: 'allocationJudge', params: { auth: true } }).catch(err => {});
					break;
				case '4':
					this.$router.push({ name: 'planChange', params: { auth: true } }).catch(err => {});
					break;
				case '5':
					this.$router.push({ name: 'achievement', params: { auth: true } }).catch(err => {});
//					this.$router.push({ name: 'achievementGroup', params: { auth: true } }).catch(err => {});
					break;
				case '6':
					this.$router.push({ name: 'businessResult', params: { auth: true } }).catch(err => {});
					break;
				case '8':
					this.$router.push({ name: 'outputReport', params: { auth: true } }).catch(err => {});
					break;
				default:
					break;
			}
		},
		async doInit () {
			try {
				// ローディング開始
				this.startLoading('データを初期化しています。しばらくおまちください。');

				let prefList = await this.$ajaxPlugin.post(API_ENDPOINT_COMMON, { action: 'get', params: JSON.stringify({ common_name: 'prefecture' })});
				this.$store.dispatch('commonListManager/setCommonData', { name: 'prefecture', listData: prefList.data.data});

				let buKbuList = await this.$ajaxPlugin.post(API_ENDPOINT_COMMON, { action: 'get', params: JSON.stringify({ common_name: 'target_kbn' })});
				this.$store.dispatch('commonListManager/setCommonData', { name: 'targetKbn', listData: buKbuList.data.data});

				let appTypeList = await this.$ajaxPlugin.post(API_ENDPOINT_COMMON, { action: 'get', params: JSON.stringify({ common_name: 'apply_type' })});
				this.$store.dispatch('commonListManager/setCommonData', { name: 'applyType', listData: appTypeList.data.data});

				let offeringStatusList = await this.$ajaxPlugin.post(API_ENDPOINT_OFFERING, { action: 'get_offering_list', params: JSON.stringify({ offering_code: this.getUserStatus.offeringCode })});
				let statusList = {};
				offeringStatusList.data.data.forEach((status) => {
					statusList[status.fase_code] = (status.fase_status == '1' ? false : true);
				});
				this.$store.dispatch('faseStatusManager/setFaseStatus', statusList );

				// マスタデータ類の取得が完了したポータル画面を表示する
//				this.$router.push({ name: 'portal', params: { auth: true } });

			} catch (e) {
				this.$logger.error(e.message);
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},		
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		})
	}
};
</script>

<style lang='scss' scoped>

.v-enter-active, .v-leave-active {
	// transition: opacity .2s;
}
.v-enter, .v-leave-to {
	// opacity: 0;
}
</style>
