<template>
	<div>
		<div class='page-header'>
			<div>請求情報登録：{{ editPatternName }}</div>
			<div>
			</div>
		</div>
		<div class='mb-5'>事業実施者：{{ this.$route.params.data.businessPractitioner }}</div>
		<div>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>請求情報</span>
				</div>
				<div class='input-form__row'>
					<span>金融機関区分</span>
					<div class='w-40'>
						<el-select ref='prefecture' v-model='formValues.bankType' clearable placeholder='選択してください' size='small'>
							<el-option v-for='item in bankTypeList' :key='item.value' :label='item.value + "：" + item.label' :value='item.value'/>
						</el-select>
					</div>
					<div class='ml-3'>
						<el-button size='mini' type='primary' @click='showSelectBankDialog'>検索</el-button>
					</div>
				</div>
				<div class='input-form__row h-8'>
					<span>金融機関</span>
					<div class='w-40'>
						<span>{{ formValues.bankCode }}：{{ formValues.bankName }}</span>
					</div>
				</div>
				<div class='input-form__row h-8'>
					<span>支店</span>
					<div class='w-40'>
						<span>{{ formValues.branchCode }}：{{ formValues.branchName }}</span>
					</div>
				</div>
				<div class='input-form__row'>
					<span>預金の種別</span>
					<div class='w-40'>
						<el-select ref='prefecture' v-model='formValues.depositType' clearable placeholder='選択してください' size='small'>
							<el-option v-for='item in depositTypeList' :key='item.value' :label='item.value + "：" + item.label' :value='item.value'/>
						</el-select>
					</div>
				</div>
				<div class='input-form__row'>
					<span>口座番号</span>
					<div class='w-20'>
						<el-input
							v-model='formValues.accountNumber'
							size='small'
							class='w-1/2'
							@blur='paddingAccountNumber'
							maxlength='7'
						>
						</el-input>
					</div>
				</div>
				<div class='input-form__row'>
					<span>口座名義（全角）</span>
					<div class='w-96'>
						<el-input
							v-model='formValues.depositName'
							size='small'
						>
						</el-input>
					</div>
				</div>
				<div class='input-form__row'>
					<span>預金の名義（全角カタカナ）</span>
					<div class='w-96'>
						<el-input
							v-model='formValues.depositNameKana'
							size='small'
						>
						</el-input>
					</div>
					<div class='ml-3'>
						<a href='https://www.smbc.co.jp/direct/help_furikomi/14.html' target='_blank'>※受取人名を入力する際のご注意</a>
					</div>
				</div>
				<div class='input-form__row'>
					<span>請求金額</span>
					<div class='w-24'>
						<el-input
							class='money'
							v-model='formValues.money'
							size='small'
							@focus='toLocaleString(false)'
							@blur='toLocaleString(true)'
						>
						</el-input>
					</div>
					<div class='ml-3'>円</div>
				</div>
				<div class='input-form__row last'>
					<span>支払日</span>
					<div class='w-20'>
						<el-date-picker
							v-model='formValues.paymentDate'
							type="date"
							placeholder='選択してください'
							value-format='yyyy-MM-dd'
							size='small'>
						</el-date-picker>
					</div>
				</div>
			</el-card>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>申請様式ファイル選択</span>
				</div>
				<div class='upload-form'>
					<div>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式第９</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai9' @drop='dropFile("yoshikiDai9", $event)' @select='selectFile("yoshikiDai9", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai9.id, uploadFileInfo.yoshikiDai9.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai9.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai9.lastUpdate }}</div>
						</div>
					</div>
					<div>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>通帳コピー</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai9B' @drop='dropFile("yoshikiDai9B", $event)' @select='selectFile("yoshikiDai9B", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai9B.id, uploadFileInfo.yoshikiDai9B.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai9B.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai9B.lastUpdate }}</div>
						</div>
					</div>
				</div>
			</el-card>
			<el-button type='primary' class='mb-8 w-full' @click.stop='registInvoiceData'>登録する</el-button>			
		</div>

		<el-dialog title='入力エラー' :visible.sync='dialogVisible.inputError'>
			<div class='p-2' v-for='(item, index) in errorList' :key='index'>
				{{ item }}
			</div>
		</el-dialog>

		<!-- 金融機関選択ダイアログ Start -->
		<el-dialog title='金融機関選択' :visible.sync='dialogVisible.selectBank'>
			<select-bank ref='selectBank' v-model='formValues.bankType' :bankType='formValues.bankType' @click='setBank' />
		</el-dialog>
		<!-- 金融機関選択ダイアログ End -->

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import FileUploadForm from '@/components/custom/FileUpdateForm';
import SelectBank from '@/components/dialog/SelectBank';

const API_ENDPOINT = '/invoice-detail';
const API_ENDPOINT_COMMON = '/common';

export default {
	name: 'InvoiceDetail',
	props: {
		params: {},
		value: {}
	},
	data () {
    	return {
			formValues: {
				bankType: '',
				bankCode: '',
				bankName: '',
				branchCode: '',
				branchName: '',
				depositType: '',
				accountNumber: '',
				depositName: '',
				depositNameKana: '',
				money: '',
				paymentDate: ''
			},
			fileList: {
				'yoshikiDai9': '',
				'yoshikiDai9B': '',
			},
			uploadFileInfo: {
				'yoshikiDai9': {id: '', fileName: '', lastUpdate: ''},
				'yoshikiDai9B': {id: '', fileName: '', lastUpdate: ''},
			},
			dialogVisible: {
				inputError: false,
				selectBank: false,
			},
			errorList: [],
			bankList: [],
			branchList: [],
			filterBankList: [],
			filterBranchList: [],
			depositTypeList: [],
			bankTypeList: [],
			isUpdate: false,
    	};
	},
	components: {
		'file-upload-form': FileUploadForm,
		'select-bank': SelectBank,
	},
	created () {
		this.applyInfoId = this.$route.params.id;
		this.getInvoiceData();
	},
	mounted () {
	},
	methods: {
		async getInvoiceData () {

			this.startLoading('データを取得しています。しばらくおまちください。');
			try {

				let bankType = await this.$ajaxPlugin.post(API_ENDPOINT_COMMON, { action: 'get', params: JSON.stringify({ common_name: 'bank_type' })});
				this.bankTypeList = bankType.data.data;

				let depositType = await this.$ajaxPlugin.post(API_ENDPOINT_COMMON, { action: 'get', params: JSON.stringify({ common_name: 'deposit_type' })});
				this.depositTypeList = depositType.data.data;

				// ファイルアップロード情報の取得
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_invoice_data',
					params: JSON.stringify({
						id: this.applyInfoId,
					})
				});
				if (result.data.result == 'OK') {
					// 画面入力項目のクリア
					Object.keys(this.formValues).forEach((key) => {
						this.formValues[key] = '';
					});
					// 添付ファイルのクリア					
					Object.keys(this.uploadFileInfo).forEach((key) => {
						this.$set(this.uploadFileInfo, key, {id: '', fileName: '', lastUpdate: ''});
					});
					// データが存在する場合は画面に反映させる
					if (result.data.data.length >= 1) {
						if (this.applyInfoId) {
							// 支店コード一覧の取得
							let branch = await this.$ajaxPlugin.post(API_ENDPOINT, {
								action: 'get_branch_list',
								params: JSON.stringify({
									bankCode: result.data.data[0].bank_code
								})
							});
							this.formValues.bankType = result.data.data[0].bank_type;
							this.formValues.bankCode = result.data.data[0].bank_code;
							this.formValues.bankName = result.data.data[0].bank_name;
							this.formValues.branchCode = result.data.data[0].branch_code;
							this.formValues.branchName = result.data.data[0].branch_name;
							this.formValues.depositType = result.data.data[0].deposit_type;
							this.formValues.accountNumber = result.data.data[0].account_number;
							this.formValues.depositName = result.data.data[0].deposit_name;
							this.formValues.depositNameKana = result.data.data[0].deposit_name_kana;
							this.formValues.money = result.data.data[0].money;
							this.formValues.paymentDate = result.data.data[0].payment_date;
							result.data.data[0].yoshiki_info.forEach((value, index) => {
								switch (value.id_name) {
									case 'yoshikiDai9':
										this.uploadFileInfo.yoshikiDai9.id = value.id;
										this.uploadFileInfo.yoshikiDai9.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai9.lastUpdate = value.last_update;
										break;
									case 'yoshikiDai9B':
										this.uploadFileInfo.yoshikiDai9B.id = value.id;
										this.uploadFileInfo.yoshikiDai9B.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai9B.lastUpdate = value.last_update;
										break;
									default:
										break;
								}
							});
							this.isUpdate = true;
						}
					}
				}
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		async registInvoiceData () {
			if (!this.formCheck()) {
				this.dialogVisible.inputError = true;
				return false;
			}
			// 預金の名義（全角カタカナ）の変換
			let convertDepositNameKana = this.zenkakuToHankaku(this.formValues.depositNameKana).toUpperCase();
			// 請求金額の数値化
			this.toLocaleString(false);
			
			this.startLoading('ファイルをアップロードしています。しばらくおまちください。');
			try {
				let params = new FormData();
				params.append('action', 'regist_invoice_data');
				params.append('params', JSON.stringify({
					'id': this.applyInfoId,
					'offeringCode': this.getUserStatus.offeringCode,
					'userId': this.getUserStatus.userId,
					'bankCode': this.formValues.bankCode,
					'branchCode': this.formValues.branchCode,
					'depositType': this.formValues.depositType,
					'accountNumber': this.formValues.accountNumber,
					'depositName': this.formValues.depositName,
					'depositNameKana': this.formValues.depositNameKana,
					'depositNameKanaConv': convertDepositNameKana,
					'money': this.formValues.money,
					'paymentDate': this.formValues.paymentDate,
				}));
				Object.keys(this.fileList).forEach((key) => {
					if (this.fileList[key] != '') {
						console.dir(this.fileList[key]);
						params.append(key, this.fileList[key]);
					}
				});

				let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
				if (response.data.size >= 1) {
					this.downloadCsvBlob(response.data, '取込処理結果.xlsx');
					this.$message({
						'type': 'success', 
						'message': '請求情報の登録が完了しました。申請様式ファイルの登録結果は「登録処理結果.xlsx」をご確認ください。',
						'duration': 5000
					});
				} else {
					this.$message({
						'type': 'success', 
						'message': '請求情報の登録が完了しました。'
					});
				}

			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
				this.getInvoiceData();
			}
		},
		formCheck () {
			this.errorList = [];
			if (!this.formValues.bankCode){
				this.errorList.push('【金融機関】を入力してください');
			}
			if (!this.formValues.branchCode){
				this.errorList.push('【支店】を入力してください');
			}
			if (!this.formValues.depositType){
				this.errorList.push('【預金の種別】を入力してください');
			}
			if (!this.formValues.accountNumber){
				this.errorList.push('【口座番号】を入力してください');
			} else if (!this.formValues.accountNumber.length == 7){
				this.errorList.push('【口座番号】7桁で入力してください');
			}
			if (!this.formValues.depositName){
				this.errorList.push('【口座名義（全角）】を入力してください');
			}
			if (!this.formValues.depositNameKana){
				this.errorList.push('【預金の名義（全角カタカナ）】を入力してください');
			} else {
				// 全角スペース、全角カタカナ、半角カタカナ、英数字、一部の記号[-\,.()/]のみ許可
				var reg = new RegExp(/^[/　+/ァ-ンヴーｧ-ﾝﾞﾟa-zA-Z0-9（）\-\\,.()\/]*$/g);
				if(!reg.test(this.formValues.depositNameKana)) {
					this.errorList.push('【預金の名義（全角カタカナ）】使用できない文字が含まれています');
				} else if (this.formValues.depositNameKana.length > 48) {
					this.errorList.push('【預金の名義（全角カタカナ）】入力可能文字数は最大で48文字までです');
				}
			}
			if (!this.formValues.money){
				this.errorList.push('【請求金額】を入力してください');
			}
			if (!this.formValues.paymentDate){
//				this.errorList.push('【支払日】を入力してください');
			}

			if (this.errorList.length >= 1) {
				return false;
			} else {
				return true;
			}
		},
		async downloadYoshikiFile (id, fileName, $event) {
			let params = new FormData();
			params.append('action', 'download_yoshiki_file');
			params.append('params', JSON.stringify({
				'id': id,
				'offering_code': this.getUserStatus.offeringCode,
				'edition': this.$route.params.data.edition,				
			}));
			let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
			if (response.data.size >= 1) {
				this.downloadCsvBlob(response.data, fileName);
			}
		},
		dropFile (type, file) {
			this.fileList[type] = file[0];
		},
		selectFile (type, file) {
			this.fileList[type] = file[0];
		},
		setBank (data) {
			this.formValues.bankCode = data[0].value;
			this.formValues.bankName = data[0].label;
			this.formValues.branchCode = data[1].value;
			this.formValues.branchName = data[1].label;
			this.dialogVisible.selectBank = false;

		},
		showSelectBankDialog() {
			if (this.formValues.bankType == '') {
				this.errorList.push('金融機関区分を選択してください');
				this.dialogVisible.inputError = true;
			} else {
				this.dialogVisible.selectBank = true;
				if (this.$refs.selectBank) {
					this.$refs.selectBank.getBankList(this.formValues.bankType);
				}
			}
		},
		zenkakuToHankaku (mae){
			let zen = new Array(
				'ア','イ','ウ','エ','オ','カ','キ','ク','ケ','コ'
				,'サ','シ','ス','セ','ソ','タ','チ','ツ','テ','ト'
				,'ナ','ニ','ヌ','ネ','ノ','ハ','ヒ','フ','ヘ','ホ'
				,'マ','ミ','ム','メ','モ','ヤ','ヰ','ユ','ヱ','ヨ'
				,'ラ','リ','ル','レ','ロ','ワ','ヲ','ン'
				,'ガ','ギ','グ','ゲ','ゴ','ザ','ジ','ズ','ゼ','ゾ'
				,'ダ','ヂ','ヅ','デ','ド','バ','ビ','ブ','ベ','ボ'
				,'パ','ピ','プ','ペ','ポ'
				,'ァ','ィ','ゥ','ェ','ォ','ャ','ュ','ョ','ッ'
				,'゛','°','、','。','「','」','ー','・','（','）', '　'
			);
			
			let han = new Array(
				'ｱ','ｲ','ｳ','ｴ','ｵ','ｶ','ｷ','ｸ','ｹ','ｺ'
				,'ｻ','ｼ','ｽ','ｾ','ｿ','ﾀ','ﾁ','ﾂ','ﾃ','ﾄ'
				,'ﾅ','ﾆ','ﾇ','ﾈ','ﾉ','ﾊ','ﾋ','ﾌ','ﾍ','ﾎ'
				,'ﾏ','ﾐ','ﾑ','ﾒ','ﾓ','ﾔ','ｲ','ﾕ','ｴ','ﾖ'
				,'ﾗ','ﾘ','ﾙ','ﾚ','ﾛ','ﾜ','ｦ','ﾝ'
				,'ｶﾞ','ｷﾞ','ｸﾞ','ｹﾞ','ｺﾞ','ｻﾞ','ｼﾞ','ｽﾞ','ｾﾞ','ｿﾞ'
				,'ﾀﾞ','ﾁﾞ','ﾂﾞ','ﾃﾞ','ﾄﾞ','ﾊﾞ','ﾋﾞ','ﾌﾞ','ﾍﾞ','ﾎﾞ'
				,'ﾊﾟ','ﾋﾟ','ﾌﾟ','ﾍﾟ','ﾎﾟ'
				,'ｱ','ｲ','ｳ','ｴ','ｵ','ﾔ','ﾕ','ﾖ','ﾂ'
				,'ﾞ','ﾟ','､','｡','｢','｣','ｰ','･','(',')', ' '
			);
			
			let ato = '';
			
			for (let i = 0; i < mae.length; i++){
				let maechar = mae.charAt(i);
				let zenindex = zen.indexOf(maechar);
				if (zenindex >= 0){
					maechar = han[zenindex];
				}
				ato += maechar;
			}
			
			return ato;
		},
		toLocaleString (isLocale) {
			if (this.formValues.money) {

				this.formValues.money = String(this.formValues.money).replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
        			return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    			});

				if (isLocale) {
					this.formValues.money = parseInt(this.formValues.money).toLocaleString();
				} else {
					this.formValues.money = Number(String(this.formValues.money).replace(/,/g, ''));
				}
			}
		},
		paddingAccountNumber () {
			if (this.formValues.accountNumber) {
				this.formValues.accountNumber = String(this.formValues.accountNumber).replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
        			return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    			});
				this.formValues.accountNumber = ('0000000' + this.formValues.accountNumber).slice(-7);
			}
		}
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		}),
		editPatternName () {
			return (this.isUpdate ? '修正' : '新規登録');
		},		
	}

};
</script>

<style lang='scss' scoped>

	.input-form {
		&__row {
			@apply flex items-center mb-3;
			> span {
				@apply flex-none w-96;
			}
			> div {
				@apply flex-none;
			}
		}
		&__row.last {
			@apply mb-0;
		}
	}

	.upload-form {
		> div {
			@apply flex items-center h-24 border p-3;
			> div:nth-child(1) {
				@apply flex-none w-40 mr-3 h-full flex items-center;
			}
			> div:nth-child(2) {
				@apply flex-none w-7/12 mr-3 h-full;
			}
			> div:nth-child(3) {
				@apply flex-grow;
			}
		}
	}

</style>
