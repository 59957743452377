<template>
    <div>
<!--
		<div class='header'>
			<div>補助対象者と同じ都道府県の審査員も表示する</div>
			<el-switch
				v-model='showSamePrefectureJudges'
				@change='handleChange'
			/>
		</div>
-->		
		<div class='mb-5'>
			<el-table
				:data="judgeList"
				style="width: 100%"
				row-key="id"
				height='500px'
				@row-click='handleRowClick'
				lazy
				:row-class-name='tableRowClassName'
			>
				<el-table-column
					align='center'
					type='index'
					width='50'
				/>
				<el-table-column
					label='審査員'
					align='center'
					width='150'
					:filters="[{ text: '審査員A', value: '1' }, { text: '審査員B', value: '2' }, { text: '審査員C', value: '3' }]"
					:filter-method="filterTag"
					>
					<template slot-scope='scope'>
						<div v-if='scope.row.judge_no != ""'>
							<el-tag
								size='mini'
								type='primary'
								disable-transitions
							>
								{{ judgeNames[scope.row.judge_no] }}
							</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column label='都道府県'>
					<el-table-column prop='prefecture_code' label='コード' width='80' />
					<el-table-column prop='prefecture_name' label='名称' width='120' />
				</el-table-column>
				<el-table-column prop='kojin_no' label='個人No' width='80' />
				<el-table-column prop='name' label='氏名' />				
			</el-table>
		</div>
        <div class='w-full'>
            <el-button class='w-full' type='primary' size='small' @click='handleRegist'>登録</el-button>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

const API_ENDPOINT = '/allocation-judge';

export default {
	name: 'SelectJudge',
	props: [
    	'value'
    ],
	data () {
		return {
			showSamePrefectureJudges: false,
			judgeList: [],
			judgeNames: {
				'1': '審査員A',
				'2': '審査員Β',
				'3': '審査員C',
			},
			judge_no: [
				{ no: '1', kojin_no: '' },
				{ no: '2', kojin_no: '' },
				{ no: '3', kojin_no: '' },
			]
		};
	},
	mounted () {
		this.$nextTick(function () {
			this.getJudgeList();
		});
	},
	created () {
	},
	methods: {
        doInit (uploadFileId) {
			this.judge_no = [
				{ no: '1', kojin_no: '' },
				{ no: '2', kojin_no: '' },
				{ no: '3', kojin_no: '' },
            ]
            this.getJudgeList(uploadFileId);
        },
		async getJudgeList (uploadFileId) {
			let id;
			if (uploadFileId) {
				id = uploadFileId;
			} else {
				id = this.value;
			}
			let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
				action: 'get_judge_list',
				params: JSON.stringify({
					'uploadFileId': id
				})
			});
			this.judgeList = result.data.data;
			Object.keys(this.judgeList).forEach((key) => {
				switch (this.judgeList[key].judge_no) {
					case '1':
						this.judge_no[0]['kojin_no'] = this.judgeList[key].kojin_no;
						break;
					case '2':
						this.judge_no[1]['kojin_no'] = this.judgeList[key].kojin_no;
						break;
					case '3':
						this.judge_no[2]['kojin_no'] = this.judgeList[key].kojin_no;
						break;
					default:
						break;
				}
			});
			//this.allocationListCount = result.data.count;
			
		},
		handleRowClick (row) {
			if (row.judge_no != '') {
				let ret = this.judge_no.filter((item) => {
					return item.kojin_no === row.kojin_no;
				});
				if (ret.length >= 1) {
					ret[0].kojin_no = '';
					row.judge_no = '';
				}
			} else {
				let ret = this.judge_no.filter((item) => {
					return item.kojin_no === ''; 
				});
				if (ret.length >= 1) {
					ret[0].kojin_no = row.kojin_no;
					row.judge_no = ret[0].no;
				} else {
				}
			}
		},
		async handleRegist () {
            this.$emit('regist', this.judge_no);
		},
		filterTag(value, row) {
			return row.judge_no === value;
		},
		handleChange () {

		},
		tableRowClassName({row, rowIndex}) {
			if (row.prefecture_code === this.value.prefectureCode) {
				return 'same-prefecture';
			} else {
				return '';
			}
		}
    },
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
			getCommonData: 'commonListManager/getCommonData',
		})
	}
};

</script>

<style lang='scss' scoped>

	.header {
		margin-bottom: 20px;
		display: grid;
		grid-template-columns: 300px 1fr;
		grid-template-rows: 100%;
	}

	.same-prefecture {
		background-color: #FFFFBB;
	}

</style>
