<template>
	<div class='group-wrap'>
		<div class='z-10'>
			<el-menu default-active='1' mode='horizontal'>
			</el-menu>
		</div>
		<div>
			<div>
	        	<router-view />
			</div>
		</div>
	</div>	
</template>

<script>

export default {
	name: 'PortalGroup',
	data () {
    	return {
    	};
	  },
	components: {
	},
	created () {
	},
	mounted () {
		// this.$router.push({ name: 'portal', params: { auth: true } }).catch(err => {});
	},
	methods: {
	}
};
</script>

<style lang='scss' scoped>

</style>
