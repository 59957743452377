<template>
	<div>
		<div class='page-header'>
			<div>実績報告：{{ editPatternName }}</div>
			<div>
				<el-button
					size='small'
					plain
					type='primary'
					icon='el-icon-error'
					@click.stop='showImportErrorList'
				>
					エラー情報
				</el-button>				
			</div>
		</div>
		<div class='mb-5'>事業実施者：{{ this.$route.params.data.businessPractitioner }}</div>
		<div>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>実績情報</span>
				</div>
				<div class='input-form__row'>
					<span>提出日</span>
					<div>
						<el-date-picker
							v-model='formValues.filingDate'
							type="date"
							placeholder='選択してください'
							value-format='yyyy-MM-dd'
							size='small'>
						</el-date-picker>
					</div>
				</div>
				<div class='input-form__row'>
					<span>事業期間</span>
					<div>
						<el-date-picker
							v-model='formValues.fromDate'
							type="date"
							placeholder='選択してください'
							value-format='yyyy-MM-dd'
							size='small'>
						</el-date-picker>
						〜
						<el-date-picker
							v-model='formValues.endDate'
							type="date"
							placeholder='選択してください'
							value-format='yyyy-MM-dd'
							size='small'>
						</el-date-picker>
					</div>
				</div>
			</el-card>
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>承認情報</span>
				</div>
				<div class='input-form__row'>
					<span>番号</span>
					<div class='w-16'>
						<el-input
							v-model="formValues.achievementNumber"
							size='small'
							maxlength='5'							
							>
						</el-input>								
					</div>
				</div>
				<div class='input-form__row last'>
					<span>発番日</span>
					<div>
						<el-date-picker
							v-model='formValues.numberingDate'
							type="date"
							placeholder='選択してください'
							value-format='yyyy-MM-dd'
							size='small'>
						</el-date-picker>
					</div>
				</div>
			</el-card>			
			<el-card class='input-form mb-8' shadow='never'> 
				<div slot='header'>
					<span>申請様式ファイル選択</span>
				</div>
				<div class='upload-form'>
					<div>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式第８</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai8' @drop='dropFile("yoshikiDai8", $event)' @select='selectFile("yoshikiDai8", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai8.id, uploadFileInfo.yoshikiDai8.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai8.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai8.lastUpdate }}</div>
						</div>
					</div>
					<div>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式第８別紙２</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai8B2' @drop='dropFile("yoshikiDai8B2", $event)' @select='selectFile("yoshikiDai8B2", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai8B2.id, uploadFileInfo.yoshikiDai8B2.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai8B2.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai8B2.lastUpdate }}</div>
						</div>
					</div>
					<div>
						<div>
							<div class='h-full bg-gray-200 flex items-center justify-center w-full'>様式第８別紙３</div>
						</div>
						<div>
							<file-upload-form :multiple='false' ref='yoshikiDai8B3' @drop='dropFile("yoshikiDai8B3", $event)' @select='selectFile("yoshikiDai8B3", $event)' />
						</div>
						<div>
							<div>ファイル名：<el-link v-on:click='downloadYoshikiFile(uploadFileInfo.yoshikiDai8B3.id, uploadFileInfo.yoshikiDai8B3.fileName, $event)' type='primary'>{{ uploadFileInfo.yoshikiDai8B3.fileName }}</el-link></div>
							<div>最終更新日：{{ this.uploadFileInfo.yoshikiDai8B3.lastUpdate }}</div>
						</div>
					</div>
				</div>
			</el-card>
			<el-button type='primary' class='mb-8 w-full' @click.stop='registAchievement'>登録する</el-button>			
		</div>

		<el-dialog title='入力エラー' :visible.sync='dialogVisible.inputError'>
			<div class='p-2' v-for='(item, index) in errorList' :key='index'>
				{{ item }}
			</div>
		</el-dialog>

		<el-dialog title='取り込みエラー' :visible.sync='dialogVisible.importError'>
			<div class='p-2' v-for='(item, index) in importErrorList' :key='index'>
				【{{ item.id_name }}】：{{ item.error_contents }}
			</div>
		</el-dialog>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import FileUploadForm from '@/components/custom/FileUpdateForm';

const API_ENDPOINT = '/achievement-detail';

export default {
	name: 'AchievementDetail',
	props: {
		params: {},
		value: {}
	},
	data () {
    	return {
			formValues: {
				applyInfoId: '',
				filingDate: '',
				fromDate: '',
				endDate: '',
				achievementNumber: '',
				numberingDate: '',
			},			
			fileList: {
				'yoshikiDai8': '',
				'yoshikiDai8B2': '',
				'yoshikiDai8B3': '',
			},
			uploadFileInfo: {
				'yoshikiDai8': {id: '', fileName: '', lastUpdate: ''},
				'yoshikiDai8B2': {id: '', fileName: '', lastUpdate: ''},
				'yoshikiDai8B3': {id: '', fileName: '', lastUpdate: ''},
			},
			dialogVisible: {
				inputError: false,
				importError: false
			},
			importErrorList: [],
			errorList: [],
			isUpdate: false
    	};
	},
	components: {
		'file-upload-form': FileUploadForm,
	},
	created () {
		this.applyInfoId = this.$route.params.id;
		this.getAchievementData();
	},
	mounted () {
	},
	methods: {
		async getAchievementData () {

			this.startLoading('データを取得しています。しばらくおまちください。');
			try {
				// ファイルアップロード情報の取得
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_achievement_data',
					params: JSON.stringify({
						id: this.applyInfoId,
					})
				});
				if (result.data.result == 'OK') {
					// 画面入力項目のクリア
					Object.keys(this.formValues).forEach((key) => {
						this.formValues[key] = '';
					});
					// 添付ファイルのクリア
					Object.keys(this.uploadFileInfo).forEach((key) => {
						this.$set(this.uploadFileInfo, key, {id: '', fileName: '', lastUpdate: ''});
					});
					// データが存在する場合は画面に反映させる
					if (result.data.data.length >= 1) {
						if (this.applyInfoId) {
							this.formValues.achievementNumber = result.data.data[0].achievement_number;
							this.formValues.numberingDate = result.data.data[0].numbering_date;
							this.formValues.filingDate = result.data.data[0].filing_date;
							this.formValues.fromDate = result.data.data[0].from_date;
							this.formValues.endDate = result.data.data[0].end_date;
							result.data.data[0].yoshiki_info.forEach((value, index) => {
								switch (value.id_name) {
									case 'yoshikiDai8':
										this.uploadFileInfo.yoshikiDai8.id = value.id;
										this.uploadFileInfo.yoshikiDai8.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai8.lastUpdate = value.last_update;
										break;
									case 'yoshikiDai8B2':
										this.uploadFileInfo.yoshikiDai8B2.id = value.id;
										this.uploadFileInfo.yoshikiDai8B2.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai8B2.lastUpdate = value.last_update;
										break;
									case 'yoshikiDai8B3':
										this.uploadFileInfo.yoshikiDai8B3.id = value.id;
										this.uploadFileInfo.yoshikiDai8B3.fileName = value.file_name;
										this.uploadFileInfo.yoshikiDai8B3.lastUpdate = value.last_update;
										break;
									default:
										break;
								}
							});
							this.isUpdate = true;
						}
					}
				}
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
			}
		},
		async registAchievement () {
			if (!this.formCheck()) {
				this.dialogVisible.inputError = true;
				return false;
			}
			this.startLoading('ファイルをアップロードしています。しばらくおまちください。');
			try {
				let params = new FormData();
				params.append('action', 'regist_achievement_data');
				params.append('params', JSON.stringify({
					'id': this.applyInfoId,
					'offeringCode': this.getUserStatus.offeringCode,
					'userId': this.getUserStatus.userId,
					'filingDate': this.formValues.filingDate,
					'fromDate': this.formValues.fromDate,
					'endDate': this.formValues.endDate,
					'achievementNumber': this.formValues.achievementNumber,
					'numberingDate': this.formValues.numberingDate,
				}));
				Object.keys(this.fileList).forEach((key) => {
					if (this.fileList[key] != '') {
						params.append(key, this.fileList[key]);
					}
				});

				let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
				if (response.data.size >= 1) {
					this.downloadCsvBlob(response.data, '取込処理結果.xlsx');
					this.$message({
						'type': 'success', 
						'message': '申請情報の登録が完了しました。申請様式ファイルの登録結果は「登録処理結果.xlsx」をご確認ください。',
						'duration': 5000
					});
				} else {
					this.$message({
						'type': 'success', 
						'message': '申請情報の登録が完了しました。'
					});
				}

			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				// ローディング停止
				this.stopLoading();
				this.getAchievementData();
			}
		},
		formCheck () {
			this.errorList = [];
			// 番号が入力されている場合、発番日が入力されていないとエラー
			if (!this.formValues.filingDate){
				this.errorList.push('【提出日】を入力してください');
			}
			if (!this.formValues.fromDate){
				this.errorList.push('【事業期間（From）】を入力してください');
			}
			if (!this.formValues.endDate){
				this.errorList.push('【事業期間（To）】を入力してください');
			}
			if (!this.formValues.achievementNumber && this.formValues.numberingDate){
				this.errorList.push('【番号】が入力されていません');
			} else if (String(this.formValues.achievementNumber).length >= 1 && String(this.formValues.achievementNumber).length > 5){
				this.errorList.push('【番号】5桁以内で入力してください');
			}
			if (this.formValues.achievementNumber && !this.formValues.numberingDate){
				this.errorList.push('【発番日】を入力してください');
			}

			if (this.errorList.length >= 1) {
				return false;				
			} else {
				return true;
			}
		},
		async downloadYoshikiFile (id, fileName, $event) {
			let params = new FormData();
			params.append('action', 'download_yoshiki_file');
			params.append('params', JSON.stringify({
				'id': id,
				'offering_code': this.getUserStatus.offeringCode,
			}));
			let response = await this.$ajaxPlugin.post(API_ENDPOINT, params, { responseType: "blob" });
			if (response.data.size >= 1) {
				this.downloadCsvBlob(response.data, fileName);
			}
		},
		dropFile (type, file) {
			this.fileList[type] = file[0];
		},
		selectFile (type, file) {
			this.fileList[type] = file[0];
		},
		async showImportErrorList () {
			try {
				// ファイルアップロード情報の取得
				let result = await this.$ajaxPlugin.post(API_ENDPOINT, {
					action: 'get_import_error_list',
					params: JSON.stringify({
						id: this.applyInfoId,
					})
				});
				if (result.data.result == 'OK') {
					// インポートファイルリストのクリア
					this.importErrorList = [];
					// データが存在する場合は画面に反映させる
					if (result.data.count >= 1) {
						this.importErrorList = result.data.data;
					} else {
						this.importErrorList.push({ 'id_name': '', 'error_contents': '取り込みエラーはありません。'});
					}
				}
			} catch (e) {
				this.$logger.error(e.message);				
			} finally {
				this.dialogVisible.importError=true;
			}

		}
	},
	computed: {
		...mapGetters({
			getUserStatus: 'userManager/getUserStatus',
		}),
		editPatternName () {
			return (this.isUpdate ? '修正' : '新規登録');
		},
	}
};
</script>

<style lang='scss' scoped>

	.input-form {
		&__row {
			@apply flex items-center mb-3;
			> span {
				@apply flex-none w-96;
			}
			> div {
				@apply flex-none;
			}
		}
		&__row.last {
			@apply mb-0;
		}
	}

	.upload-form {
		> div {
			@apply flex items-center h-24 border p-3;
			> div:nth-child(1) {
				@apply flex-none w-40 mr-3 h-full flex items-center;
			}
			> div:nth-child(2) {
				@apply flex-none w-7/12 mr-3 h-full;
			}
			> div:nth-child(3) {
				@apply flex-grow;
			}
		}
	}

</style>
